import React from 'react'
import CardInfo from '../CardInfo'
import { toWords } from 'number-to-words'
import CourseLaunchLink from '../../CourseLaunchLink/CourseLaunchLink'
import { redirectUserToExternalLink } from '@outlier-org/lst-auth-react'

const UnsubmittedCard = ({
  course,
  note,
  assignmentsCount,
  assignment,
  courseBaseUrl
}) => {
  const singleAssignment = assignmentsCount === 1
  const assignmentUrl = `${courseBaseUrl}/#/${assignment.chapter_uuid}/writing_assignment`

  const description = singleAssignment ? (
    <>
      You have an unsubmitted assignment! Submit the
      <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
        {` ${assignment.title} `}
      </CourseLaunchLink>or visit the
      <CourseLaunchLink url={courseBaseUrl} rel='noopener noreferrer'> Course Homepage </CourseLaunchLink>
      to keep reviewing.
    </>
  ) : (
    <>
      You have {toWords(assignmentsCount)} unsubmitted assignments!
      Submit the
      <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
        {` ${assignment.title} `}
      </CourseLaunchLink>or visit the
      <CourseLaunchLink url={courseBaseUrl} rel='noopener noreferrer'> Course Homepage </CourseLaunchLink>
      to view the other assignments.
    </>
  )

  return (
    <CardInfo
      course={course}
      note={note}
      description={description}
      primaryBtnTxt='Continue'
      secondaryBtnTxt={singleAssignment ? 'Review' : 'View All'}
      dataTestId='writing-unsubmitted'
      handlePrimaryButton={() => {
        redirectUserToExternalLink(assignmentUrl)
      }}
      handleSecondaryButton={() => {
        redirectUserToExternalLink(courseBaseUrl)
      }}
    />
  )
}

export default UnsubmittedCard
