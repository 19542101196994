import { redirectUserToExternalLink } from '@outlier-org/lst-auth-react'
import { OUTLIER_PARTNERS_URL } from '../Constants/domains'

export const publicRoutes = [
  '/guardian-permission',
  '/audience-consent',
  '/login'
]

export const isPublicRoute = (pathName) => {
  if (!pathName) {
    pathName = window.location.hash.split('#')[1]
  }

  return publicRoutes.includes(pathName)
}

export const redirectToPartnersStatic = () => {
  const partnersStaticWebAppUrl = OUTLIER_PARTNERS_URL
  redirectUserToExternalLink(partnersStaticWebAppUrl)
}
