import flatten from 'lodash/flatten'
import mergeWith from 'lodash/mergeWith'
import inRange from 'lodash/inRange'
import {
  getCurrentExamDate,
  getCohortExamDates,
  getCohortSpecialDays,
  getCohortStartSecondsSinceEpoch
} from '../utilities/cohort'
import { isUserAuditor } from '../utilities/user'
import {
  addDaysToDate,
  addDaysToDateInSecs,
  dateToSecondsSinceEpoch,
  getElevenFiftyNinePmInSecs,
  getNextWeekdayDate,
  getNextWeekdayDateInSecs,
  getTwelveAmInSecs,
  secondsToFormattedDay,
  secondsToFormattedDateShort,
  secondsSinceEpoch
} from '../utilities/dateTime'
import { INTENSIVE_COHORT_DURATION } from '../Constants/cohort'
import { EXAM, ESSAY, BREAK } from '../Constants/examType'
import camelCase from 'lodash/camelCase'
import { OUTLIER_STUDENT_DASHBOARD_URL } from '../Constants/domains'

export const PRACTICE_EXAM = 'Practice Exam'
export const SECTION = 'section'

export const getFinalDropOrWithdrawalDate = date => {
  if (!date) return ''
  return dateToSecondsSinceEpoch(new Date(date))
}

export const todayAtTwelveAmInSecs = new Date().setHours(0, 0, 0, 0) / 1000

export const customMerge = (string1, string2) => {
  if (typeof string1 !== 'string' || typeof string2 !== 'string') return
  if (string1 === string2 || (string1.includes('|') && string2.includes('|'))) {
    return string1
  }

  return string1 + ', ' + string2
}

export const getDynamicSchedules = schedules => {
  if (!schedules) return []

  const currentTimeInSecs = secondsSinceEpoch()
  return schedules.filter(
    schedule =>
      inRange(
        currentTimeInSecs,
        schedule.startDateInSecs,
        schedule.endDateInSecs + 1
      ) || schedule.startDateInSecs >= currentTimeInSecs
  )
}

export const getCourseResourcesSchedule = ({
  latestCohort,
  syllabusData,
  courseData
}) => {
  const startDate = getCohortStartSecondsSinceEpoch(latestCohort)
  const cohortSpecialDays = getCohortSpecialDays(latestCohort)
  const cohortExamDates = getCohortExamDates(latestCohort)
  const isAuditor = isUserAuditor(latestCohort) ||
    latestCohort?.name.toUpperCase().includes('AUDIT')

  const {
    duration,
    finalDropDate,
    finalWithdrawalDate,
    milestones: cohortMilestones
  } = latestCohort || {}

  const { chapters } = courseData || {}

  const dropAndWithdrawalDates = {
    finalWithdrawalDate: getFinalDropOrWithdrawalDate(finalWithdrawalDate),
    finalDropDate: getFinalDropOrWithdrawalDate(finalDropDate)
  }
  const dates = { cohortExamDates, startDate, dropAndWithdrawalDates }
  const isIntensive = duration <= INTENSIVE_COHORT_DURATION
  const cohortDetails = {
    cohortMilestones,
    isIntensive,
    cohortSpecialDays,
    chapters
  }
  let formattedFetchedSchedule = getFormattedSectionSchedule(
    syllabusData,
    dates,
    cohortDetails
  )
  if (isAuditor) {
    const lastDayWithdrawText = 'Last day to withdraw with a grade of W'
    formattedFetchedSchedule = formattedFetchedSchedule
      .filter(schedule => !schedule.title.includes(lastDayWithdrawText))
  }

  return formattedFetchedSchedule
}

export const getIntensiveDates = params => {
  const { day, cohortStartDate } = params || {}
  const activityStartDate = addDaysToDateInSecs(cohortStartDate, day - 1)

  const endDate = addDaysToDate(activityStartDate, 1)
  const endDateInSecs = getElevenFiftyNinePmInSecs(activityStartDate)
  const isActive = inRange(todayAtTwelveAmInSecs, activityStartDate, endDateInSecs + 1)

  return {
    startDate: secondsToFormattedDateShort(activityStartDate, 'numeric'),
    startDateInSecs: activityStartDate,
    endDate,
    endDateInSecs,
    isActive
  }
}

export const getStandardDates = params => {
  const { week, cohortStartDate } = params || {}
  const firstMondayAfterStartDate = getNextWeekdayDateInSecs(cohortStartDate, 1)
  const activityStartDate = getSectionStartDate(
    week, { firstMondayAfterStartDate, sectionStartDate: cohortStartDate }
  )

  const isWeekOne = week === 1
  const nextSundayDate = getNextWeekdayDate(cohortStartDate, 7)
  const nextSundayDateInSecs = getElevenFiftyNinePmInSecs(
    getNextWeekdayDateInSecs(cohortStartDate, 7)
  )
  const endDate = isWeekOne ? nextSundayDate : addDaysToDate(activityStartDate, 6)
  const endDateInSecs = isWeekOne
    ? nextSundayDateInSecs
    : getElevenFiftyNinePmInSecs(addDaysToDateInSecs(activityStartDate, 6))
  const isActive = inRange(todayAtTwelveAmInSecs, activityStartDate, endDateInSecs + 1)

  return {
    startDate: secondsToFormattedDateShort(activityStartDate, 'numeric'),
    startDateInSecs: activityStartDate,
    endDate,
    endDateInSecs,
    isActive
  }
}

export const getFormattedTermBreaks = params => {
  const { isIntensive, termBreaks, cohortStartDate } = params || {}
  if (!termBreaks?.length) return []

  const formattedBreaks = termBreaks.reduce((breaks, currentBreak) => {
    const { title, week, day } = currentBreak || {}
    const dates = isIntensive
      ? getIntensiveDates({ day, cohortStartDate })
      : getStandardDates({ week, cohortStartDate })
    const {
      startDate,
      startDateInSecs,
      endDate,
      endDateInSecs,
      isActive
    } = dates || {}

    const previousBreak = breaks[breaks.length - 1]
    const isConsecutiveBreak = previousBreak?.week === week - 1 ||
        previousBreak?.day === day - 1
    if (isConsecutiveBreak) {
      previousBreak.endDate = endDate
      previousBreak.endDateInSecs = endDateInSecs
      if (!isNaN(week)) previousBreak.week = week
      else if (!isNaN(day)) previousBreak.day = day
      breaks[breaks.length - 1] = previousBreak
      return breaks
    }

    breaks.push({
      ...currentBreak,
      startDate,
      startDateInSecs,
      endDate,
      endDateInSecs,
      title,
      textLink: {
        text: isActive && 'Open Checklist',
        handleClick: (updateContext) => {
          updateContext({ openChecklist: true })
        }
      }
    })
    return breaks
  }, [])

  return formattedBreaks
}

export const getFormattedSectionSchedule = (
  fetchedSchedule,
  dates,
  cohortDetails
) => {
  if (!fetchedSchedule?.length) return []
  const {
    isIntensive,
    cohortSpecialDays,
    cohortMilestones,
    chapters
  } = cohortDetails

  const { cohortExamDates, startDate, dropAndWithdrawalDates } = dates
  const twelveAmOnStartDateInSecs = getTwelveAmInSecs(startDate)
  let intensiveIndex = 0
  const allSectionSchedules = fetchedSchedule.map((schedule, index) => {
    const intensiveStartDate = addDaysToDateInSecs(
      twelveAmOnStartDateInSecs,
      intensiveIndex
    )
    const isSaturday = new Date(intensiveStartDate * 1000).getDay() === 6
    const isSunday = new Date(intensiveStartDate * 1000).getDay() === 0
    if (isSunday) {
      intensiveIndex += 2
    } else if (isSaturday) {
      intensiveIndex += 3
    } else { intensiveIndex += 1 }

    return schedule.sections.map(section => {
      const dayData = { day: intensiveIndex }
      const weekData = { week: index + 1 }
      const scheduleDayOrWeek = isIntensive ? dayData : weekData
      return {
        ...section,
        ...scheduleDayOrWeek,
        schedules: schedule.sections
      }
    })
  })

  const examSchedule = flatten(allSectionSchedules).filter(
    a => a.assignmentType === EXAM
  )
  const essaySchedule = flatten(allSectionSchedules).filter(
    a => a.assignmentType === ESSAY
  )
  const formattedEssaySchedule = getFormattedEssaySchedule(
    essaySchedule,
    cohortMilestones,
    chapters
  )
  const sectionSchedules = allSectionSchedules
    .map(schedule => schedule.filter(s => s.assignmentType === SECTION))
    .filter(array => array.length)

  const sectionSchedule = sectionSchedules.flatMap((schedule) => {
    let newSchedule = {}
    const sections = schedule.filter(s => s.assignmentType === SECTION)
    const lengthOfSections = sections.length

    sections.forEach((sch) => {
      // combine all sections objects with their values into one
      newSchedule = mergeWith(newSchedule, sch, customMerge)
    })
    newSchedule.title = `Section ${schedule[0].title.split('|')[1].trim()}`
    if (lengthOfSections > 1) {
      newSchedule.title = `Sections ${schedule[0].title
        .split('|')[1]
        .trim()} - ${sections[lengthOfSections - 1].title.split('|')[1].trim()}`
    }

    return newSchedule
  })
  const formattedSpecialDays = getFormattedSpecialDays(cohortSpecialDays)

  const formattedSectionSchedule = isIntensive
    ? getIntensiveSchedule(sectionSchedule, twelveAmOnStartDateInSecs)
    : getSectionSchedule(sectionSchedule, twelveAmOnStartDateInSecs)
  const formattedExamSchedule = getExamSchedule(examSchedule, cohortExamDates)
  const { midTerm1StartDate } = cohortExamDates
  const practiceExam = getPracticeExam(midTerm1StartDate)
  const formattedDropAndWithdrawalSchedule = getDropAndWithdrawalSchedule(
    dropAndWithdrawalDates
  )
  const firstSchedule = {
    startDate: secondsToFormattedDateShort(startDate, 'numeric'),
    startDateInSecs: startDate,
    title: 'Semester Begins',
    endDate: getNextWeekdayDate(startDate, 7),
    endDateInSecs: getElevenFiftyNinePmInSecs(
      getNextWeekdayDateInSecs(startDate, 7)
    ),
    textLink: {
      text: 'Watch the Orientation'
    }
  }

  const termBreaks = flatten(allSectionSchedules).filter(
    schedule => schedule.assignmentType === BREAK
  )
  const formattedTermBreaks = getFormattedTermBreaks({
    isIntensive, termBreaks, cohortStartDate: twelveAmOnStartDateInSecs
  })

  const formattedSchedule = [
    ...formattedTermBreaks,
    ...formattedSectionSchedule,
    ...formattedSpecialDays,
    ...formattedDropAndWithdrawalSchedule,
    ...formattedEssaySchedule,
    ...formattedExamSchedule,
    ...practiceExam
  ].sort((a, b) => a.startDateInSecs - b.startDateInSecs)

  return [firstSchedule, ...formattedSchedule]
}

export const getFormattedSpecialDays = specialDays => {
  if (!specialDays?.length) return []

  return specialDays.map(specialDay => {
    const {
      startDate: startDateInSecs,
      endDate: endDateInSecs,
      name: title,
      numberOfSpecialDays
    } = specialDay

    return {
      startDateInSecs: getTwelveAmInSecs(startDateInSecs),
      startDate: secondsToFormattedDateShort(startDateInSecs, 'numeric'),
      endDate: endDateInSecs
        ? addDaysToDate(startDateInSecs, numberOfSpecialDays)
        : null,
      endDateInSecs:
        endDateInSecs || getElevenFiftyNinePmInSecs(startDateInSecs),
      textLink: ' ',
      title
    }
  })
}

export const getFormattedEssaySchedule = (
  essays,
  cohortMilestones,
  chapters
) => {
  if (!essays?.length || !cohortMilestones?.length) return []

  return essays.map(essay => {
    const updatedTitle = essay.title.split('|')[1].trim()
    const currentEssay = cohortMilestones.find(({ assignmentList }) => {
      if (!assignmentList) return false
      return assignmentList.map(list => list.name).includes(essay.title)
    })
    if (!currentEssay) return {}

    const { chapter_uuid: chapterUuid } = chapters?.find(
      chapter => currentEssay.datoAssignmentUUID === chapter.chapter_uuid
    ) || {}

    const { lockTime, unlockTime, description, finalAssignment } = currentEssay
    const essayStartDateInSecs = dateToSecondsSinceEpoch(new Date(unlockTime))
    const essayEndDateInSecs = dateToSecondsSinceEpoch(new Date(lockTime))

    const isActive = inRange(
      todayAtTwelveAmInSecs,
      essayStartDateInSecs,
      essayEndDateInSecs + 1
    )

    return {
      ...essay,
      description,
      finalAssignment,
      startDate: secondsToFormattedDateShort(essayStartDateInSecs, 'numeric'),
      startDateInSecs: essayStartDateInSecs,
      endDate: secondsToFormattedDateShort(essayEndDateInSecs, 'numeric'),
      endDateInSecs: essayEndDateInSecs,
      title: updatedTitle,
      chapterUuid: chapterUuid,
      textLink: {
        text: isActive && 'View Assignment',
        hashLink: `/${chapterUuid}/writing_assignment`
      }
    }
  })
}

const getDropAndWithdrawalSchedule = dates => {
  const dropAndWithdrawalSchedule = []
  const { finalWithdrawalDate, finalDropDate } = dates
  if (finalWithdrawalDate) {
    dropAndWithdrawalSchedule.push({
      title: 'Last day to withdraw with a grade of W',
      assignmentType: 'dropAndWithdrawal',
      startDateInSecs: getTwelveAmInSecs(finalWithdrawalDate),
      endDateInSecs: getElevenFiftyNinePmInSecs(finalWithdrawalDate),
      startDate: secondsToFormattedDateShort(finalWithdrawalDate, 'numeric'),
      textLink: {
        text: 'Contact Outlier',
        handleClick: () =>
          (window.location.href = `https://${OUTLIER_STUDENT_DASHBOARD_URL}/#/contact`)
      }
    })
  }
  if (finalDropDate) {
    dropAndWithdrawalSchedule.push({
      title: 'Last day to drop or audit',
      assignmentType: 'dropAndWithdrawal',
      startDateInSecs: getTwelveAmInSecs(finalDropDate),
      endDateInSecs: getElevenFiftyNinePmInSecs(finalDropDate),
      startDate: secondsToFormattedDateShort(finalDropDate, 'numeric'),
      textLink: {
        text: 'Check Eligibility',
        handleClick: () =>
          (window.location.href = `https://${OUTLIER_STUDENT_DASHBOARD_URL}/#/course-exit`)
      }
    })
  }

  return dropAndWithdrawalSchedule
}

const getSectionSchedule = (sectionSchedule, startDate) => {
  let sectionStartDate = startDate
  const nextSundayDate = getNextWeekdayDate(startDate, 7)
  const nextSundayDateInSecs = getElevenFiftyNinePmInSecs(
    getNextWeekdayDateInSecs(startDate, 7)
  )
  const firstMondayAfterStartDate = getNextWeekdayDateInSecs(startDate, 1)

  return [...sectionSchedule].map(currSchedule => {
    const { week } = currSchedule
    const isWeekOne = week === 1
    const dates = { firstMondayAfterStartDate, sectionStartDate }
    sectionStartDate = getSectionStartDate(week, dates)

    const formattedSchedule = {
      ...currSchedule,
      startDate: secondsToFormattedDateShort(sectionStartDate, 'numeric'),
      startDateInSecs: sectionStartDate,
      endDate: isWeekOne ? nextSundayDate : addDaysToDate(sectionStartDate, 6),
      endDateInSecs: isWeekOne
        ? nextSundayDateInSecs
        : getElevenFiftyNinePmInSecs(addDaysToDateInSecs(sectionStartDate, 6))
    }

    return formattedSchedule
  })
}

export const getExamHashLink = title => {
  if (!title) return ''

  switch (title) {
    case 'Midterm':
      return 'midtermExam'
    case 'Midterm 1':
      return 'midtermExam1'
    case 'Midterm 2':
      return 'midtermExam2'
    default:
      return camelCase(title)
  }
}

export const getExamSchedule = (examSchedule, cohortExamDates) => {
  return examSchedule.map(currSchedule => {
    const { title } = currSchedule
    const {
      start: examStartDate,
      stop: examEndDate
    } = getCurrentExamDate(currSchedule, cohortExamDates)

    if (!examStartDate || !examEndDate) return null

    const updatedTitle = title.split('|')[1].trim()
    const hashLink = getExamHashLink(updatedTitle)
    const isActive = inRange(
      todayAtTwelveAmInSecs,
      examStartDate,
      examEndDate + 1
    )

    return {
      ...currSchedule,
      title: updatedTitle,
      startDate: secondsToFormattedDateShort(examStartDate),
      startDateInSecs: examStartDate,
      endDate: secondsToFormattedDateShort(examEndDate),
      endDateInSecs: examEndDate,
      textLink: {
        text: isActive && 'start exam',
        hashLink: `/#${hashLink}`
      }
    }
  }).filter(Boolean)
}

export const getIntensiveSchedule = (sectionSchedule, startDate) => {
  if (!sectionSchedule || !sectionSchedule.length || !startDate) return []
  let sectionStartDate = startDate

  return sectionSchedule.map((currSchedule, index) => {
    const { title: currentTitle, day = 0 } = currSchedule
    sectionStartDate = addDaysToDateInSecs(startDate, day - 1)
    let endDate = null
    let endDateInSecs = getElevenFiftyNinePmInSecs(sectionStartDate)
    const nextSchedule = sectionSchedule[index + 1] || {}
    const previousSchedule = sectionSchedule[index - 1] || {}
    const { title: nextTitle } = nextSchedule
    const { title: previousTitle } = previousSchedule
    const isSameSection = currentTitle === nextTitle
    if (isSameSection) {
      endDate = addDaysToDate(sectionStartDate, 1)
      endDateInSecs = addDaysToDateInSecs(sectionStartDate, 1)
    }
    if (previousTitle === currentTitle && !isSameSection) return null

    const formattedSchedule = {
      ...currSchedule,
      startDate: secondsToFormattedDateShort(sectionStartDate, 'numeric'),
      startDateInSecs: sectionStartDate,
      endDate,
      endDateInSecs
    }

    return formattedSchedule
  }).filter(Boolean)
}

export const getSectionStartDate = (week, dates) => {
  if (!week || !dates) return

  const isWeekOne = week === 1
  const isWeekTwo = week === 2

  const { firstMondayAfterStartDate, sectionStartDate } = dates
  if (isWeekTwo) return firstMondayAfterStartDate
  if (isWeekOne) return sectionStartDate

  const days = (week - 2) * 7
  return addDaysToDateInSecs(firstMondayAfterStartDate, days)
}

export const getPracticeExam = midTerm1StartDate => {
  if (!midTerm1StartDate) return []
  // subtract a week (7 days) from midTerm1StartDate in seconds
  // one day is 86_400 seconds —> 24 hr * 60 mins * 60 secs
  const practiceExamStartDate = midTerm1StartDate - 86_400 * 7

  const isPracticeExamOpen = practiceExamStartDate <= secondsSinceEpoch()

  return [
    {
      title: PRACTICE_EXAM,
      assignmentType: EXAM,
      startDateInSecs: getTwelveAmInSecs(practiceExamStartDate),
      startDate: secondsToFormattedDateShort(practiceExamStartDate, 'numeric'),
      endDate: addDaysToDate(practiceExamStartDate, 6),
      endDateInSecs: addDaysToDateInSecs(practiceExamStartDate, 6),
      textLink: {
        text: isPracticeExamOpen && 'start exam'
      }
    }
  ]
}

export const schedulesFormattedOnlyWeeksOrDays = (
  schedules = [],
  isIntensive = false
) => {
  const filterSchedule = schedule => schedule.week || schedule.day
  const filterIntensiveSchedule = schedule =>
    schedule.week || schedule.day || schedule?.assignmentType === EXAM
  const weekDaySchedules = schedules
    .filter(isIntensive ? filterIntensiveSchedule : filterSchedule)
    .sort((a, b) => {
      return (a.week || a.day) - (b.week || b.day)
    })
  const uniqueWeekDaySchedules = []
  weekDaySchedules.forEach(schedule => {
    if (uniqueWeekDaySchedules[schedule.week - 1]) {
      uniqueWeekDaySchedules[schedule.week - 1].push(schedule)
    } else {
      uniqueWeekDaySchedules.push([schedule])
    }
  })
  return uniqueWeekDaySchedules
}

export const getIntensiveCohortWeeks = (schedules) => {
  if (!schedules) return []
  const scheduleBulkDays = []
  const scheduleWeeks = []
  let scheduleDayWeeks = []
  let nextScheduleDay = ''
  schedules.forEach((schedule, index) => {
    const nextIndex = index + 1
    if (nextIndex !== schedules.length) {
      if (!schedules[nextIndex][0]) return
      nextScheduleDay = secondsToFormattedDay(schedules[nextIndex][0].startDateInSecs)
    }
    scheduleDayWeeks.push(schedule[0])
    if (nextScheduleDay === 'Mon') {
      scheduleBulkDays.push(scheduleDayWeeks)
      scheduleDayWeeks = []
    }
  })
  scheduleBulkDays.push(scheduleDayWeeks)
  scheduleBulkDays.forEach((scheduleDays, index) => {
    const lastIndex = scheduleDays.length - 1
    if (!scheduleDays[0]) return
    const scheduleWeek = {
      days: scheduleDays,
      week: index + 1,
      startDate: scheduleDays[0].startDate,
      startDateInSecs: scheduleDays[0].startDateInSecs,
      endDate: scheduleDays[lastIndex].endDate,
      endDateInSecs: scheduleDays[lastIndex].endDateInSecs,
      assignmentType: scheduleDays[0].assignmentType,
      title: scheduleDays[lastIndex].title
    }
    scheduleWeeks.push(scheduleWeek)
  })
  return scheduleWeeks
}

export const getFormattedSchedule = (
  courseResourcesSchedule,
  isIntensive = false
) => {
  const formattedSchedules = schedulesFormattedOnlyWeeksOrDays(
    courseResourcesSchedule,
    isIntensive
  )
  if (formattedSchedules.length === 0) return []
  const isStandard = formattedSchedules[0][0].week !== undefined
  return isStandard
    ? formattedSchedules.map(formattedSchedule => formattedSchedule[0])
    : getIntensiveCohortWeeks(formattedSchedules)
}

export function getRecommendedProgress (courseResourcesSchedule) {
  const formattedSchedules = getFormattedSchedule(courseResourcesSchedule)
  const cohortSchedulesLength = formattedSchedules.length
  let recommendedProgressWeek = getDynamicSchedules(formattedSchedules)[0]
  let lastWeek = formattedSchedules[cohortSchedulesLength - 1]
  if (!recommendedProgressWeek) {
    recommendedProgressWeek = lastWeek
  }

  const indexOfRP = recommendedProgressWeek?.week - 1
  const recommendedProgressLastWeek = formattedSchedules[indexOfRP - 1]
  const recommendedProgressLastTwoWeeks = formattedSchedules[indexOfRP - 2]

  lastWeek = lastWeek?.week
  const recommendedProgressWeekValue =
    (recommendedProgressWeek?.week / lastWeek) * 100
  const oneWeekRecommendedProgress =
    (recommendedProgressLastWeek?.week / lastWeek) * 100 || 0
  const twoWeekRecommendedProgress =
    (recommendedProgressLastTwoWeeks?.week / lastWeek) * 100 || 0

  return {
    values: {
      currentValueRP: Math.round(recommendedProgressWeekValue),
      lastWeekValueRP: Math.round(oneWeekRecommendedProgress),
      lastTwoWeeksValueRP: Math.round(twoWeekRecommendedProgress)
    }
  }
}
