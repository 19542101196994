import React from 'react'
import CardInfo from './CardInfo'
import config from '../../config'

import { getVisitedSectionInfo } from '../../utilities/section'
import { ExamLink } from './style'
import CourseLaunchLink from '../CourseLaunchLink/CourseLaunchLink'

const GuessworkCard = ({
  course,
  note,
  exam,
  handleStartButtonClick,
  courseProgress,
  chapterNo,
  sectionNo,
  reviewChapterTitle,
  reviewChapterUrl,
  reviewFirstSectionUUID
}) => {
  const { id } = course
  const { studentProgress, sectionData } = courseProgress
  const { studentAnswers } = studentProgress

  // get last visited guesswork section data
  const { section_exe: sectionExe, section_uuid: sectionUuid } = sectionData
  const { guesswork } = sectionExe

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`

  const { completedQuestion, totalQuestion, questionId } =
    getVisitedSectionInfo(Object.keys(studentAnswers), guesswork)

  // check if guesswork is completed or not
  const completedGuesswork =
    Object.keys(studentProgress['guesswork-complete'])
  const isGuessworkCompleted = completedGuesswork.includes(sectionUuid)

  const guessworkUrl = `${sectionUrl}/${questionId}`

  const {
    isStudyGuideOpen,
    openExamStudyGuide,
    title,
    studyGuideNote,
    examOpen,
    examLoading
  } = exam

  const getDescription = () => {
    const isExamOpenAndGuessWorkCompleted = isGuessworkCompleted && examOpen
    const isStudyGuideOpenAndGuessWorkCompleted = isGuessworkCompleted && isStudyGuideOpen
    const remainingQuestions = totalQuestion - completedQuestion
    const isMultipleCompletedQuestions = completedQuestion > 1 ? 's' : ''

    if (isExamOpenAndGuessWorkCompleted && reviewFirstSectionUUID) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <CourseLaunchLink url={guessworkUrl} rel='noopener noreferrer'>
            Guesswork
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {'. Start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>, {'or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (isExamOpenAndGuessWorkCompleted) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <CourseLaunchLink url={guessworkUrl} rel='noopener noreferrer'>
            Guesswork
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {!isStudyGuideOpen && '. Start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {', or view the '}
              <span onClick={() => openExamStudyGuide()}>Study Guide</span>
              {' to review the course material'}
            </>
          )}
            .
        </>
      )
    }

    if (examOpen && reviewFirstSectionUUID) {
      return (
        <>
          {'It\'s exam time! Continue to complete the remaining '}
          {`${totalQuestion - completedQuestion} questions in `}
          <CourseLaunchLink url={guessworkUrl} rel='noopener noreferrer'>
            Guesswork
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {', start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>, {' or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (examOpen) {
      return (
        <>
          {'It\'s exam time! Continue to complete the remaining '}
          {`${totalQuestion - completedQuestion} questions in `}
          <CourseLaunchLink url={guessworkUrl} rel='noopener noreferrer'>
            Guesswork
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          , {!isStudyGuideOpen && 'or'} start
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {' or view the '}
              <span onClick={() => openExamStudyGuide()}>Study Guide</span>
              {' to review the course material'}
            </>
          )}
            .
        </>
      )
    }

    if (isStudyGuideOpenAndGuessWorkCompleted) {
      return (
        <>
          {`The ${title} is coming up! You finished `}
          <CourseLaunchLink url={guessworkUrl} rel='noopener noreferrer'>
            Guesswork
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {'. View '}
          <span onClick={() => openExamStudyGuide()}>Study Guide</span>
          {' or continue to keep working on the material.'}
        </>
      )
    }

    if (isStudyGuideOpen) {
      return (
        <>
          {`The ${title} is coming up! View the `}
          <span onClick={() => openExamStudyGuide()}>Study Guide</span>
          {` or continue to complete the remaining ${remainingQuestions} `}
          <CourseLaunchLink url={guessworkUrl} rel='noopener noreferrer'>
            Guesswork
          </CourseLaunchLink>
          {` question${isMultipleCompletedQuestions} in `}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          .
        </>
      )
    }

    if (isGuessworkCompleted) {
      return (
        <>
          {'You finished '}
          <CourseLaunchLink url={guessworkUrl} rel='noopener noreferrer'>
            Guesswork
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          . Continue to keep working on the material
          {reviewFirstSectionUUID && (
            <>
              {' or start '}
              <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
              </a>
            </>
          )}
          .
        </>
      )
    }

    return (
      <>
        {`You completed ${completedQuestion} `}
        <CourseLaunchLink url={guessworkUrl} rel='noopener noreferrer'>
          Guesswork
        </CourseLaunchLink>
        {` question${isMultipleCompletedQuestions} in `}
        <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
          Section {chapterNo}.{sectionNo}
        </CourseLaunchLink>
        {'. Continue to complete the remaining '}
        {remainingQuestions} questions
        {reviewFirstSectionUUID && (
          <>
            {' or start '}
            <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
            </a>
          </>
        )}
        .
      </>
    )
  }

  const navigateToCourseUrl = () =>
    (window.location.href = isGuessworkCompleted ? sectionUrl : guessworkUrl)

  const navigateToReviewChapterUrl = () =>
    (window.location.href = reviewChapterUrl)

  const data = {
    dataTestId: isGuessworkCompleted
      ? 'guesswork-completed'
      : 'guesswork-inprogress',
    description: getDescription(),
    note,
    secondaryBtnTxt:
      examOpen || reviewFirstSectionUUID
        ? 'Start'
        : isStudyGuideOpen && 'Continue',
    handleSecondaryButton: () => {
      examOpen
        ? handleStartButtonClick()
        : reviewFirstSectionUUID
          ? navigateToReviewChapterUrl()
          : navigateToCourseUrl()
    },
    studyGuideNote,
    primaryBtnTxt: examOpen ? 'Continue' : isStudyGuideOpen ? 'View' : 'Continue',
    handlePrimaryButton: () => {
      examOpen
        ? navigateToCourseUrl()
        : isStudyGuideOpen
          ? openExamStudyGuide()
          : navigateToCourseUrl()
    }
  }

  return (
    <CardInfo
      course={course}
      {...data}
    />
  )
}

export default GuessworkCard
