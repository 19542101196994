import React from 'react'
import { BannerContainer, Icon, Message } from './styles'

const BasicBanner = ({
  showBanner,
  bannerText,
  linkText,
  showIcon,
  passedInOnClickFunction
}) => {
  if (showBanner === false) return null

  const onClickFunction = () => {
    passedInOnClickFunction()
  }

  return (
    <BannerContainer>
      <Message>
        {bannerText}&nbsp;
        <span onClick={onClickFunction}>{linkText}</span>
        {showIcon && <Icon />}.
      </Message>
    </BannerContainer>
  )
}

BasicBanner.displayName = 'BasicBanner'
export default BasicBanner
