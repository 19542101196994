import { AMAZON_REFUND_PATH } from './accountArea'
import { CODING_ASSIGNMENT, WRITING_ASSIGNMENT } from './chapterTypes'

export const NODE_ENV = process.env.NODE_ENV

export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN
export const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const REACT_APP_SHOPIFY_URL = process.env.REACT_APP_SHOPIFY_URL
export const REACT_APP_SATISMETER_WRITE_KEY = process.env.REACT_APP_SATISMETER_WRITE_KEY
export const PUBLIC_URL = process.env.PUBLIC_URL

export const REACT_APP_ID = process.env.REACT_APP_ID
export const REACT_APP_SSO_AUTH_SAPI_BASE_URL = process.env.REACT_APP_SSO_AUTH_SAPI_BASE_URL
export const REACT_APP_SSO_AUTH_CONTEXT_ID = process.env.REACT_APP_SSO_AUTH_CONTEXT_ID
export const REACT_APP_SSO_AUTH_GATEWAY_CLIENT_ID = process.env.REACT_APP_SSO_AUTH_GATEWAY_CLIENT_ID
export const REACT_APP_SSO_BASE_URL = process.env.REACT_APP_SSO_BASE_URL
export const REACT_APP_SSO_RBS_TOKEN_GRANT_TYPE = process.env.REACT_APP_SSO_RBS_TOKEN_GRANT_TYPE

export const FIRST_EDITION_SUBDOMAIN = 'firstedition'
export const PROCTORIO = 'proctorio'
export const EMAIL_VERIFIED = 'email-verified'
export const TERMS_AGREEMENT = 'terms-agreement'
export const DEGREE_REGISTRATION_INCOMPLETE = 'degree-registration-incomplete'
export const PERMISSION_REQUESTED_KEY = 'guardian-permission-requested'
export const LESSON0_VIEWED = 'lesson-zero-viewed'
export const PERMISSION_GRANTED_KEY = 'guardian-permission-granted'
export const GGU_WELCOME_PAGE_VIEW = 'ggu-welcome-page-view'
export const INSTRIDE_WELCOME_PAGE_VIEW = 'instride-welcome-page-view'
export const HIDE_DASHBOARD_ITEM = 'hide-dashboard-item'
export const FULL_WIDTH_PATHS = [
  '/contact',
  '/account',
  '/refundToken',
  '/confirmation',
  '/catalog',
  '/achievements',
  AMAZON_REFUND_PATH,
  '/onboarding-chapter',
  '/add-tokens'
]
export const IN_PROGRESS = 'In Progress'
export const UPCOMING = 'Upcoming'
export const AUDITING = 'Auditing'
export const SINGLE_MIDTERM_EXAM = 'Midterm Exam'
export const MIDTERM_EXAM_1 = 'Midterm Exam 1'
export const MIDTERM_EXAM_2 = 'Midterm Exam 2'
export const FINAL_EXAM = 'Final Exam'
export const DAYS_BEFORE_EXAM = 10
export const DESMOS_SCRIPT_LINK =
  'https://www.desmos.com/api/v1.6/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6'
export const MATHJAX_SCRIPT_LINK =
  'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_SVG'
export const COLLEGE_SUCCESS = 'collegesuccess'
export const NUMBER_TO_ORDINALS = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth'
}

export const CODING_ASSIGNMENT_PATH = 'coding_assignment'
export const WRITING_ASSIGNMENT_PATH = 'writing_assignment'
export const ASSIGNMENT_PATH_NAME = {
  [CODING_ASSIGNMENT]: CODING_ASSIGNMENT_PATH,
  [WRITING_ASSIGNMENT]: WRITING_ASSIGNMENT_PATH
}

// List of Realize org roles  that can access the partner dashboard from
// the student dashboard
export const PARTNER_DASHBOARD_ROLES = [
  'Teacher',
  'Pearson Administrator',
  'Customer Administrator'
]

export const REACT_APP_TRIGGER_REALIZE_CLASS_SYNC = process.env.REACT_APP_TRIGGER_REALIZE_CLASS_SYNC || 'false'
