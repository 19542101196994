import React, { useState } from 'react'
import { WebAuth } from 'auth0-js'
import {
  PrimaryButton,
  SecondaryButton
} from '../Buttons/style/index'
import {
  SettingItemEditContent,
  SettingInput
} from './style'
import api from '../../api'
import utils from '../../utilities/index'
import { NODE_ENV, REACT_APP_CLIENT_ID, REACT_APP_DOMAIN } from '../../Constants'

const EmailPassword = ({
  newEmail,
  setNewEmail,
  studentEmail,
  modes,
  setCurrentMode,
  isLoading,
  setIsLoading
}) => {
  const webAuth = new WebAuth({
    domain: REACT_APP_DOMAIN,
    clientID: REACT_APP_CLIENT_ID
  })

  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [serverError, setServerError] = useState('')

  const areValidInputs = async () => {
    const isValidEmail = utils.validateEmail(newEmail)
    setEmailError(!isValidEmail)
    if (!isValidEmail) return false

    const result = password &&
      await api.verifyPassword(studentEmail, password)
    const { access_token: token } = result || {}
    const isValidPassword = !!token
    setPasswordError(!isValidPassword)
    return isValidPassword
  }

  const signupUser = () => {
    try {
      webAuth.signup({
        connection: 'Username-Password-Authentication',
        email: newEmail,
        password: password
      }, err => {
        if (err) {
          const { code, description } = err
          if (code === 'user_exists' ||
            (NODE_ENV === 'development' &&
              code === 'invalid_signup')) {
            return setCurrentMode(modes.EMAIL_UPDATE_SUCCESS)
          }
          console.error('Error when signing up: ', description)
          setServerError(description)
          return
        }

        // handle signup success case
        setCurrentMode(modes.EMAIL_UPDATE_SUCCESS)
      })
    } catch (error) {
      console.error('Error when signing up: ', error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const verifyEmailAndSendLink = async () => {
    setIsLoading(true)
    setServerError('')
    const validInputs = await areValidInputs()
    setIsLoading(false)
    if (!validInputs) return

    setIsLoading(true)
    const result = await api.verifyEmailAndSendCode({ newEmail, type: 'link' })
    setIsLoading(false)
    const { success, message } = result || {}
    if (!success) return setServerError(message)

    setIsLoading(true)
    const response = await api.updateNewEmailInATAndAC(newEmail)
    setIsLoading(false)
    const { success: updateSuccess, message: updateMessage } = response || {}
    if (!updateSuccess) return setServerError(updateMessage)

    setIsLoading(true)
    signupUser()
  }

  return (
    <SettingItemEditContent className='content' style={{ marginTop: '150px' }}>
      <span>
        Updated Email
      </span>
      {serverError &&
        <span className='error'>
          {serverError}
        </span>}
      {emailError &&
        <span data-testid='emailError' className='error'>
          Please enter a valid email.
        </span>}
      <SettingInput
        hasError={emailError || serverError}
        className='fs-exclude'
        type='email'
        data-testid='email'
        onChange={e => setNewEmail(e.target.value)}
        value={newEmail}
        disabled={isLoading}
      />
      <span>
        Current Password
      </span>
      {passwordError &&
        <span data-testid='passwordError' className='error'>
          Please enter the password you use to log in.
        </span>}
      <SettingInput
        hasError={passwordError}
        className='fs-exclude'
        type='password'
        data-testid='password'
        onChange={e => setPassword(e.target.value)}
        value={password}
        disabled={isLoading}
      />
      <div>
        <SecondaryButton
          data-testid='btn-cancel-email'
          onClick={() => setCurrentMode(modes.VERIFY_EMAIL_MESSAGE)}
          disabled={isLoading}
        >
          cancel
        </SecondaryButton>
        <PrimaryButton
          data-testid='btn-save-email'
          onClick={verifyEmailAndSendLink}
          disabled={isLoading}
        >
          Submit
        </PrimaryButton>
      </div>
    </SettingItemEditContent>
  )
}

export default EmailPassword
