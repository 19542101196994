import React, { useEffect, useContext, useRef, useCallback } from 'react'
import debounce from 'lodash/debounce'
import md5 from 'md5'
import { AppContext } from '../ContextProvider/ContextProvider'
import qs from 'qs'
import * as typeformEmbed from '@typeform/embed'
import { ExitFormPageContainer, FormWrapper, BackToLink, FormDetailWrapper } from './style'
import { ExitTitle } from '../ExitFormPage/style'
import { Redirect } from 'react-router-dom'
import {
  TYPE_FORM_IDS, FORM_TYPES, FORM_URLS
} from '../../data/constants'
import {
  convertEasternTimeToLocalTime,
  dateToSecondsSinceEpoch,
  getDateTimeWithShortTimezone
} from '../../utilities/dateTime'
import api from '../../api'
import { getCohortDeadlinesTime } from '../../utilities/cohort'
import {
  getLatestCohort
} from '../../utilities/course'
import { NPS_EXIT_FORM } from '../../Constants/eventType'
import { MULTIPLE } from '../../Constants/frequency'
import { useUserStore } from '@outlier-org/lst-auth-react'
import { REACT_APP_SATISMETER_WRITE_KEY } from '../../Constants'

const getFormText = (formFields) => {
  const { courseName, formId, cohort } = formFields

  const getFormType = () => {
    const {
      drop,
      'drop_v2.1': dropV21,
      'auditDrop_v2.1': auditDropV21,
      auditDrop,
      scholarshipDrop
    } = TYPE_FORM_IDS
    const { drop: dropFormType, withdrawal, audit } = FORM_TYPES
    const isDropForm = [
      drop,
      auditDrop,
      dropV21,
      auditDropV21,
      scholarshipDrop
    ].includes(formId)
    if (isDropForm) return dropFormType
    if (formId === TYPE_FORM_IDS.withdrawal) return withdrawal
    if (formId === TYPE_FORM_IDS.audit) return audit
    return FORM_TYPES.exception
  }

  const dropDateET = convertEasternTimeToLocalTime(cohort.finalDropDate)
  const dropDate = dropDateET
    ? getDateTimeWithShortTimezone(dateToSecondsSinceEpoch(dropDateET))
      .replace(',', ' at')
    : ''

  const withdrawalDateET = convertEasternTimeToLocalTime(cohort.finalWithdrawalDate)
  const withdrawalDate = withdrawalDateET
    ? getDateTimeWithShortTimezone(dateToSecondsSinceEpoch(withdrawalDateET))
      .replace(',', ' at')
    : ''

  const zendeskMainLink = 'https://outlierorg.zendesk.com/hc/en-us/articles'

  switch (getFormType()) {
    case FORM_TYPES.drop:
      return {
        title: 'drop Form',
        content: (
          <>
            <p data-testid='drop-text'>You can drop {courseName} by completing this form before your cohort’s drop period ends on {dropDate}.</p>
            <p> Once you complete this form, you’ll be fully removed from this course and it won’t appear on your academic record.</p>
            <p><a href={`${zendeskMainLink}/360046323012-Dropping-a-Course`}>Learn more about dropping an Outlier course.</a></p>
          </>
        )
      }
    case FORM_TYPES.withdrawal:
      return {
        title: 'withdrawal form',
        content: (
          <>
            <p>You can withdraw from {courseName} by completing this form before your cohort’s withdrawal period ends on {withdrawalDate}.</p>
            <p>Withdrawing means that you’ll receive a “W” on your transcript instead of a letter grade. You’ll still have unlimited access to the non-graded course material.</p>
            <p><a href={`${zendeskMainLink}/360038128551-Withdrawing-From-a-Course`}>Learn more about withdrawing an Outlier course.</a></p>
          </>
        )
      }
    case FORM_TYPES.audit:
      return {
        title: 'audit form',
        content: (
          <>
            <p>You can audit {courseName} by completing this form before your cohort’s drop period ends on <span data-testid='audit-last-date'>{dropDate}.</span></p>
            <p>You won’t receive a letter grade or course credit, but you’ll have no-pressure access to quizzes and unlimited access to non-graded material for the course.</p>
            <p><a href={`${zendeskMainLink}/360053939312-Auditing-a-course`}>Learn more about auditing an Outlier course.</a></p>
          </>
        )
      }
    case FORM_TYPES.exception:
      return {
        title: 'exception form',
        content: (
          <>
            <p>We understand that sometimes, things happen that are outside of our control. Even though it’s past your cohort’s withdrawal deadline, we’re happy to discuss accommodations if an unpredictable event is affecting your studies.</p>
            <p> Use this form to tell us more about your situation and we’ll get in touch with the options that might be available to you.</p>
          </>
        )
      }
    default:
      return undefined
  }
}
const handleRedirection = ({
  reloadContext,
  formOutcomes,
  history,
  pathname,
  courseName,
  cohort
}) => {
  const { audit: auditType } = FORM_TYPES
  const { audit: auditFormId } = TYPE_FORM_IDS
  const {
    T3: T3Outcome,
    successPlan: successPlanOutcome,
    audit: auditOutcome
  } = formOutcomes

  const { dropTimePT } = getCohortDeadlinesTime(cohort, auditType)

  if (auditOutcome) {
    return history.push({
      pathname: `${pathname}/course-exit/${auditType}/form`,
      state: {
        formId: `${auditFormId}`,
        courseName,
        cohort,
        deadline: dropTimePT
      }
    })
  }

  if (T3Outcome) {
    window.open(FORM_URLS.timeTracking)
    setTimeout(() => history.push('/'), 3000)
  }

  if (successPlanOutcome) {
    window.open(FORM_URLS.successPlan)
    setTimeout(() => history.push('/'), 3000)
  }

  setTimeout(() => {
    reloadContext()
    history.push('/')
  }, 3000)
}

const ExitFormPage = ({ history, location }) => {
  const { studentData, courses, reloadContext } = useContext(AppContext)
  const user = useUserStore(state => state.user)
  const locationState = location.state
  const formRef = useRef(null)
  const { pathname } = location
  const npsRatingRef = useRef(null)
  const formSubmissiondRef = useRef(false)

  const trackExitFormEvent = useCallback(() => {
    if (!locationState) return

    const { courseName } = locationState
    const activeCourse =
      courses.find(course => course.displayName === courseName) || {}
    const cohort = getLatestCohort(activeCourse)

    const npsRating = npsRatingRef.current
    const exitFormSubmitted = formSubmissiondRef.current

    const dropPage = location.pathname.endsWith('/course-exit/drop')
    const withdrawPage = location.pathname.endsWith('/course-exit/withdraw')
    const isExitFormPage = dropPage || withdrawPage

    const shouldTrackEvent = isExitFormPage && (npsRating || exitFormSubmitted)

    const exitForm = location.pathname.endsWith('/course-exit/drop') ? 'drop' : 'withdraw'

    if (!shouldTrackEvent) return

    api.submitTrackedEvent({
      properties: {
        course_name: activeCourse?.displayName,
        cohort_name: cohort?.name,
        studentID: studentData.id,
        attemptID: cohort?.attemptID,
        exitForm,
        exitFormSubmitted,
        npsRating: npsRating || 'N/A'
      },
      event: NPS_EXIT_FORM,
      frequency: MULTIPLE
    })
  }, [courses, locationState, studentData, location])

  useEffect(() => {
    window.onbeforeunload = () => trackExitFormEvent()
    return () => {
      trackExitFormEvent()
      window.onbeforeunload = null
    }
  }, [trackExitFormEvent])

  useEffect(() => {
    if (locationState && formRef.current) {
      const {
        drop,
        withdrawal,
        auditDrop,
        'drop_v2.1': dropV21,
        'auditDrop_v2.1': auditDropV21
      } = TYPE_FORM_IDS
      const {
        formId, courseName, cohort, deadline
      } = locationState
      const isNewDropFrom = [dropV21, auditDropV21].includes(formId)
      localStorage.setItem('courseName', courseName)
      const embedForm = async () => {
        const studentId = await api.getStudentId()
        const { attemptID, id } = cohort || {}
        const hiddenFields = {
          student_id: studentId,
          cohort_id: id,
          ...(isNewDropFrom ? { attempt_id: attemptID } : {})
        }
        const queryString = qs.stringify(hiddenFields, { encodeValuesOnly: true })
        const typeformURL = `https://form.typeform.com/to/${formId}?${queryString}`
        typeformEmbed.makeWidget(
          formRef.current,
          typeformURL,
          {
            onSubmit: async (event) => {
              const isFormRejected = Date.now() > deadline
              if (isFormRejected) {
                return history.push({ pathname: `${pathname}/course-exit/form-rejected`, state: { formId, courseName } })
              }
              formSubmissiondRef.current = true

              const newForm = [
                drop, withdrawal, auditDrop, dropV21, auditDropV21
              ].includes(formId)
              if (newForm) {
                const formOutcomes = await api.getTypeFormResponses(
                  formId, event.response_id
                )
                handleRedirection({ formOutcomes, history, courseName, cohort, pathname, reloadContext })
              }

              if (!newForm) {
                setTimeout(() => window.location.reload(), 3000)
              }
            },
            transferableUrlParameters: [
              'student_id',
              'cohort_id',
              ...(isNewDropFrom ? ['attempt_id'] : [])
            ]
          }
        )
      }
      embedForm()
      const loadSatismeter = () => {
        const { courseName } = locationState
        const exitForm =
          location.pathname.endsWith('/course-exit/drop') ? 'drop' : 'withdraw'

        const activeCourse =
          courses.find(course => course.displayName === courseName) || {}
        const cohort = getLatestCohort(activeCourse)

        const testAttempt = cohort?.testAttempt
        const isContractorEmployee = studentData?.contractorEmployee
        const isNotStudent = studentData?.notStudent
        const isOutlierStudent =
          !isContractorEmployee && !isNotStudent && !testAttempt

        window.satismeter({
          writeKey: REACT_APP_SATISMETER_WRITE_KEY,
          userId: md5(user.email),
          traits: {
            attempt_id: cohort?.attemptID,
            course_name: courseName,
            cohort_name: cohort?.name,
            student_email: user.email,
            outlier_student: isOutlierStudent,
            exit_form: exitForm
          },
          events: {
            submit: debounce(response => {
              const { answers } = response || {}
              npsRatingRef.current = answers?.[0]?.value || ''
            }, 5000),
            dismiss: () => {
              npsRatingRef.current = 'Survey Dismissed'
            }
          }
        })
      }
      const shouldLoadSatismeter =
        location.pathname.endsWith('/course-exit/drop') ||
        location.pathname.endsWith('/course-exit/withdraw')
      if (shouldLoadSatismeter) loadSatismeter()
    }
    // eslint-disable-next-line
  }, [locationState, history, location, studentData, courses])

  if (locationState) {
    const { formId, courseName, cohort, scholarship } = locationState
    const formFields = {
      formId,
      courseName,
      cohort,
      scholarship
    }

    const { title, content } = getFormText(formFields)

    return (
      <ExitFormPageContainer>
        <BackToLink title='Manage Course' path={`/account/manage-courses/${courseName}`} />
        <div className='content'>
          <ExitTitle>
            {title}
          </ExitTitle>

          <FormDetailWrapper>
            {content}
          </FormDetailWrapper>

          <FormWrapper data-testid='formContainer' ref={formRef} />
        </div>
      </ExitFormPageContainer>
    )
  } else {
    const courseName = localStorage.getItem('courseName')
    if (courseName) {
      return <Redirect to={`/account/manage-courses/${courseName}`} />
    } else {
      return <Redirect to='/account/manage-courses/' />
    }
  }
}

export default ExitFormPage
