import { OUTLIER_BASE_DOMAIN } from './domains'

export const PERSONAL_INFO = 'Personal info'
export const COURSE_MANAGEMENT = 'Course management'
export const STUDY_BLOCKS = 'Study blocks'
export const LOGIN_SECURITY = 'Login + security'
export const VOUCHER_UPLOAD = 'Voucher upload'
export const TRANSCRIPTS = 'Transcripts'
export const AMAZON_REFUND = 'Refund request'
export const TOKENS = 'Tokens'
export const SUBSCRIPTION = 'Subscription'

export const ACCOUNT_PATH = {
  PERSONAL_INFO_PATH: 'personalInfo',
  COURSE_MANAGEMENT_PATH: 'manage-courses',
  STUDY_BLOCKS_PATH: 'study-blocks',
  SUBSCRIPTION_PATH: 'subscription',
  SUBSCRIPTION_CANCELLATION: 'subscription-cancellation',
  LOGIN_SECURITY_PATH: 'loginSecurity',
  VOUCHER_UPLOAD_PATH: 'voucher',
  TRANSCRIPTS_PATH: 'transcripts',
  TOKENS_PATH: 'tokens'
}

const {
  PERSONAL_INFO_PATH,
  COURSE_MANAGEMENT_PATH,
  STUDY_BLOCKS_PATH,
  SUBSCRIPTION_PATH,
  LOGIN_SECURITY_PATH,
  VOUCHER_UPLOAD_PATH,
  TRANSCRIPTS_PATH,
  TOKENS_PATH
} = ACCOUNT_PATH

export const ACCOUNT_MENU = {
  [PERSONAL_INFO_PATH]: PERSONAL_INFO,
  [COURSE_MANAGEMENT_PATH]: COURSE_MANAGEMENT,
  [STUDY_BLOCKS_PATH]: STUDY_BLOCKS,
  [SUBSCRIPTION_PATH]: SUBSCRIPTION,
  [LOGIN_SECURITY_PATH]: LOGIN_SECURITY,
  [VOUCHER_UPLOAD_PATH]: VOUCHER_UPLOAD,
  [TRANSCRIPTS_PATH]: TRANSCRIPTS,
  [TOKENS_PATH]: TOKENS
}

export const LEGAL_NAME = 'Legal name'
export const PREFERRED_NAME = 'Preferred name'
export const PHONE_NUMBER = 'Phone number'
export const DUE_DURATION = 8

// Login + Security page

export const EMAIL = 'Email'
export const PASSWORD = 'Password'

export const EDIT_EMAIL_MODES = {
  EMAIL_PASSWORD_ENTRY: 'emailPasswordEntry',
  CODE_VERIFICATION: 'codeVerification',
  EMAIL_UPDATE_SUCCESS: 'emailUpdateSuccess'
}

export const CONTENT = {
  ACCOUNT: 'account',
  REFUND_TOKEN: 'refundToken'
}

export const ExitOptions = {
  AUDIT: 'Audit',
  DROP: 'Drop',
  TRANSFER: 'Transfer',
  WITHDRAW: 'Withdraw',
  REQUEST_EXCEPTION: 'Request and exeption'
}

export const StudentStatus = {
  SIGNED_UP: 'Signed Up',
  PREDROP: 'PreDrop',
  ENROLLED: 'Enrolled',
  AUDIT: 'Audit',
  AUDIT_TRANSCRIPT: 'Audit Transcript',
  DROP: 'Drop',
  MELT: 'Melt',
  ADMINSTRATIVE_DROP: 'Administrative Drop',
  WITHDRAWN: 'Withdrawn'
}

export const TRANSFER_COHORT_STATES = {
  INTRO: 'intro',
  TRANSFER: 'transfer',
  REVIEW: 'review'
}

// Amazon Refund

export const AMAZON_REFUND_PATH = '/amazon-refund-request'
export const GGU_REFUND_URL = 'https://www.ggu.edu/admissions/graduate/costs-and-aid/tuition-and-fees/policies/#credit-balance-payment-policy'

export const AMAZON_REFUND_REQUEST_PAGES = {
  REFUND_REQUEST: 'refund-request',
  EMBEDDED_FORM: 'embedded-form',
  REQUEST_RECEIVAL: 'request-receival',
  REFUND_REQUESTED: 'refund-requested'
}

export const AMAZON_REFUND_FORM_ID = 'Ujn4BCcg'

export const COLLEGE_PATHWAYS = 'College Pathways'

export const COLLEGE_PATHWAYS_CERTIFICATE = {
  requiredCourse: 'College Writing I',
  mathElectives: ['College Algebra', 'Precalculus'],
  liberalArtsElectives: [
    'Introduction to Psychology',
    'Introduction to Sociology',
    'Introduction to Philosophy'
  ],
  businessElectives: [
    'Principles of Economics',
    'Introduction to Business'
  ]
}

export const PITTSBURGH_TRANSCRIPTS_LINK = 'https://www.registrar.pitt.edu/students/transcriptsverifications'
export const GGU_TRANSCRIPTS_LINK = 'https://tsorder.studentclearinghouse.org/school/ficecode/00120500'

export const OUTLIER_SUBSCRIPTIONS_QA_LINK = `https://help.${OUTLIER_BASE_DOMAIN}/hc/en-us/articles/17761017243028-About-Outlier-Subscriptions`

// Study Blocks

export const WEEKDAYS = [
  { name: 'Mon' },
  { name: 'Tue' },
  { name: 'Wed' },
  { name: 'Thu' },
  { name: 'Fri' },
  { name: 'Sat' },
  { name: 'Sun' }
]

export const STUDY_COMMITMENT = {
  FULL_TIME: 'Full-time',
  PART_TIME: 'Part-time',
  FREE_TIME: 'Free-time'
}
