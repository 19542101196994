import React, { useState, useEffect, useContext, useMemo } from 'react'
import config from '../../config'
import cloneDeep from 'lodash/cloneDeep'
import { AppContext } from '../ContextProvider/ContextProvider'
import { RenderCard, RenderNoCoursesCard } from './RenderCard'
import CardInfo from './CardInfo'
import {
  getNeedsReviewingAttempts,
  hasNeedsReviewingDegreeStatus,
  getCourseTermState,
  hasUnofficalWithdrawStatus,
  filterGoogleDataAnalyticsIChapters
} from '../../utilities/course'
import {
  WhatsNextHeading
} from './style'
import { OUTLIER_BASE_DOMAIN } from '../../Constants/domains'

const WhatsNext = ({ courses }) => {
  const {
    studentAttempts,
    isGGUStudent,
    isActiveGGUStudent
  } = useContext(AppContext)
  const [filteredCourses, setCourses] = useState([])

  const needsReviewingAttempts = useMemo(() => {
    return getNeedsReviewingAttempts(studentAttempts)
  }, [studentAttempts])

  const handleCourses = (courseId) => {
    const endedCourses = localStorage.getItem('endedCourses')
      ? JSON.parse(localStorage.getItem('endedCourses')) : []
    if (!endedCourses.includes(courseId)) endedCourses.push(courseId)
    localStorage.setItem('endedCourses', JSON.stringify(endedCourses))

    const clonnedCourses = cloneDeep(filteredCourses)
    const courseIndex = clonnedCourses.findIndex(course => course.id === courseId)
    clonnedCourses.splice(courseIndex, 1)

    setCourses(clonnedCourses)
  }

  useEffect(() => {
    const endedCourses = localStorage.getItem('endedCourses')
      ? JSON.parse(localStorage.getItem('endedCourses')) : []

    const filteredCourses = courses.filter(course => {
      const isNeedsReviewingDegree = hasNeedsReviewingDegreeStatus(
        needsReviewingAttempts, course.id
      )

      const isUnofficialWithdraw = hasUnofficalWithdrawStatus(
        studentAttempts, course.id
      )

      if (isNeedsReviewingDegree || isUnofficialWithdraw) return false

      const currentTime = Date.now()
      const courseTermState = getCourseTermState(course, currentTime)
      const courseDismissed = endedCourses.includes(course.id)
      const courseDismissedAndInProgress =
        (courseDismissed && courseTermState !== 'termCompleted')

      return course.cohort &&
        (!courseDismissed || courseDismissedAndInProgress)
    })
    setCourses(filteredCourses)
  }, [
    courses,
    needsReviewingAttempts,
    studentAttempts
  ])

  const getGGUCollegeSuccessCard = () => {
    const collegeSuccessCourse =
      courses.find(course => config.isCollegeSuccessCourse(course?.id))
    if (!collegeSuccessCourse) return null
    return (
      <CardInfo
        dataTestId='ggu-college-success-card'
        course={collegeSuccessCourse}
        description='We will be reaching out via email for next steps in the application and enrollment process. In the meantime, check out the free College Success course for some helpful tips to prepare.'
        primaryBtnTxt='CONTINUE'
        handlePrimaryButton={() => {
          window.location.href = `https://collegesuccess.${config.FIRST_EDITION_SUBDOMAIN}.${OUTLIER_BASE_DOMAIN}`
        }}
      />
    )
  }

  if (!filteredCourses.length) return (<RenderNoCoursesCard />)

  const currentTime = Date.now()
  return (
    <>
      <WhatsNextHeading data-testid='courses-card'>
        What's Next
      </WhatsNextHeading>
      {isGGUStudent && !isActiveGGUStudent && getGGUCollegeSuccessCard()}
      {filteredCourses.map((course, key) => {
        const isCollegeSuccessCourse = config.isCollegeSuccessCourse(course?.id)
        if (isGGUStudent && isCollegeSuccessCourse) return null
        const courseTermState = getCourseTermState(course, currentTime)

        const { chapters, cohort: { dateStart }, id } = course
        const filteredChapters = filterGoogleDataAnalyticsIChapters(chapters, id, dateStart)
        const filteredCourse = { ...course, chapters: filteredChapters }
        return (
          <RenderCard
            key={key}
            course={filteredCourse}
            courseTermState={courseTermState}
            handleCourses={handleCourses}
            currentTime={currentTime}
          />
        )
      })}
    </>
  )
}

export default WhatsNext
