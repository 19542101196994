import React, { useContext } from 'react'
import { AppContext } from '../ContextProvider/ContextProvider'
import BasicBanner from './BasicBanner'
import { redirectToPartnersStatic } from '../../utilities/authUtils'

const PartnerDashboardBanner = () => {
  const {
    isContextLoading,
    isGGUDegreeDataLoading
  } = useContext(AppContext) || {}

  if (isContextLoading || isGGUDegreeDataLoading) return null

  return (
    <BasicBanner
      showBanner
      bannerText='Manage courses in the '
      linkText='Partner Dashboard'
      showIcon
      passedInOnClickFunction={redirectToPartnersStatic}
    />
  )
}

PartnerDashboardBanner.displayName = 'PartnerDashboardBanner'

export default PartnerDashboardBanner
