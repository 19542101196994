import React from 'react'
import CardInfo from './CardInfo'
import config from '../../config'
import { ExamLink } from './style'
import CourseLaunchLink from '../CourseLaunchLink/CourseLaunchLink'

const LectureCard = ({
  course,
  note,
  exam,
  handleStartButtonClick,
  courseProgress,
  lectureInProgress,
  chapterNo,
  sectionNo,
  reviewChapterTitle,
  reviewChapterUrl,
  reviewFirstSectionUUID
}) => {
  const { id } = course

  const { sectionData: { section_uuid: sectionUuid } } = courseProgress

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const { id: lectureId, remainingTime, order, timeSpent, completed } = lectureInProgress
  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const lectureUrl = `${sectionUrl}/${lectureId}`
  const {
    isStudyGuideOpen,
    openExamStudyGuide,
    title,
    studyGuideNote,
    examOpen,
    examLoading
  } = exam

  const getDescription = (statement) => {
    const isExamOpenAndLectureCompleted = completed && examOpen
    const isStudyGuideOpenAndLectureCompleted = completed && isStudyGuideOpen
    const isExamOpenAndLectureIncomplete = !completed && examOpen
    const isStudyGuideOpenAndLectureIncomplete = !completed && isStudyGuideOpen

    if (isExamOpenAndLectureCompleted && reviewFirstSectionUUID) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
            Lecture {order || ''}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {'. Start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {', or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (isExamOpenAndLectureCompleted) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
            Lecture {order || ''}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {!isStudyGuideOpen && '. Start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {', or view the '}
              <span onClick={() => openExamStudyGuide()}>Study Guide</span>
              {' to review the course material'}
            </>
          )}
            .
        </>
      )
    }

    if (isStudyGuideOpenAndLectureCompleted) {
      return (
        <>
          {`The ${title} is coming up! You finished `}
          <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
            Lecture {order || ''}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {'. View the '}
          <span onClick={() => openExamStudyGuide()} rel='noopener noreferrer'>
            Study Guide
          </span>
          {' or continue to keep working on the material.'}
        </>
      )
    }

    if (completed) {
      return (
        <>
          {'You finished '}
          <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
            Lecture {order || ''}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          . Continue to keep working on the material.
        </>
      )
    }

    if (isExamOpenAndLectureIncomplete && reviewFirstSectionUUID) {
      return (
        <>
          {`It's exam time! Continue to ${statement} of `}
          <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
            Lecture {order || ''}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          , {'start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>, {'or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (isExamOpenAndLectureIncomplete) {
      return (
        <>
          {`It's exam time! Continue to ${statement} of `}
          <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
            Lecture {order || ''}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          , {!isStudyGuideOpen && 'or'} start
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {' or view the '}
              <span onClick={() => openExamStudyGuide()}>Study Guide</span>
              {' to review the course material.'}
            </>
          )}
            .
        </>
      )
    }

    if (isStudyGuideOpenAndLectureIncomplete) {
      return (
        <>
          {`The ${title} is coming up! View the `}
          <span onClick={() => openExamStudyGuide()} rel='noopener noreferrer'>
            Study Guide
          </span>
          {' or continue to watch '}
          <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
            Lecture {order || ''}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          .
        </>
      )
    }

    if (reviewFirstSectionUUID) {
      return (
        <>
          Continue to
          {remainingTime
            ? ` watch the remaining ${remainingTime} mins of `
            : ' finish the '}
          <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
            Lecture {order ?? ''}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {reviewFirstSectionUUID && (
            <>
              {' or start '}
              <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
              </a>
            </>
          )}
          .
        </>
      )
    }

    return (
      <>
        {`You watched ${timeSpent ? `${timeSpent} mins of ` : ''}`}
        <CourseLaunchLink url={lectureUrl} rel='noopener noreferrer'>
          Lecture {order || ''}
        </CourseLaunchLink>
        {' in '}
        <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
          Section {chapterNo}.{sectionNo}
        </CourseLaunchLink>
        . Continue to {statement}.
      </>
    )
  }

  let data = null

  const navigateToSection = () =>
    (window.location.href = sectionUrl)

  const navigateToLecture = () =>
    (window.location.href = lectureUrl)

  const navigateToReviewChapterUrl = () =>
    (window.location.href = reviewChapterUrl)

  if (completed) {
    // if lecture completed
    data = {
      dataTestId: 'lecture-completed',
      description: getDescription(),
      note,
      studyGuideNote,
      secondaryBtnTxt: examOpen ? 'Start' : isStudyGuideOpen && 'Continue',
      handleSecondaryButton: () => {
        examOpen ? handleStartButtonClick() : navigateToSection()
      },
      primaryBtnTxt: examOpen ? 'Continue' : isStudyGuideOpen ? 'View' : 'Continue',
      handlePrimaryButton: () => {
        examOpen
          ? navigateToSection()
          : isStudyGuideOpen
            ? openExamStudyGuide()
            : navigateToSection()
      }
    }
  } else {
    // if lecture is in progress
    const endStatement = remainingTime
      ? `watch the remaining ${remainingTime} mins`
      : 'finish the lecture'

    data = {
      dataTestId: 'lecture-inprogress',
      description: getDescription(endStatement),
      note,
      studyGuideNote,
      secondaryBtnTxt:
        examOpen || reviewFirstSectionUUID
          ? 'Start'
          : isStudyGuideOpen && 'Continue',
      handleSecondaryButton: () => {
        examOpen
          ? handleStartButtonClick()
          : reviewFirstSectionUUID
            ? navigateToReviewChapterUrl()
            : navigateToLecture()
      },
      primaryBtnTxt: examOpen ? 'Continue' : isStudyGuideOpen ? 'View' : 'Continue',
      handlePrimaryButton: () => {
        examOpen
          ? navigateToLecture()
          : isStudyGuideOpen
            ? openExamStudyGuide()
            : navigateToLecture()
      }
    }
  }

  return (
    <CardInfo
      course={course}
      {...data}
    />
  )
}

export default LectureCard
