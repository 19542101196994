import React, { useContext, useEffect, useState } from 'react'
import $ from 'jquery'

import { AppContext } from '../ContextProvider/ContextProvider'
import config from '../../config'
import {
  Footer,
  ListGroupWrapper,
  ListGroup,
  ListItem,
  Link
} from './style'
import { PRIVACY_URL, TERMS_URL } from '../../Constants/domains'

const AppFooter = () => {
  const {
    courses,
    isContextLoading,
    isStudioCohort
  } = useContext(AppContext) || {}
  const [isUserSnapLoaded, setIsUserSnapLoaded] = useState(false)

  const addFeedBackScript = apiKey => {
    setIsUserSnapLoaded(true)
    window.onUsersnapCXLoad = api => {
      api.init({
        button: null
      })

      // Feedback customized icon
      const img = document.createElement('img')
      img.src = '/images/Feedback-icon.png'
      img.setAttribute('id', 'feedback_icon')
      img.setAttribute('alt', 'Contact Outlier')
      const feedbackIcon = document.getElementsByTagName('head')[0].appendChild(img)
      $(feedbackIcon).appendTo('body')

      /*
      Added a JQuery handler on the click event of the feedback Icon !
      Now if someone clicks on that icon, the Javascript Method will be executed to show up Usersnap
      */

      $('#feedback_icon').on('click', function () {
        api.open()
      })
    }

    const script = document.createElement('script'); script.async = 1
    script.src = `https://widget.usersnap.com/load/${apiKey}?onload=onUsersnapCXLoad`
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  useEffect(() => {
    if (!courses || isContextLoading || isUserSnapLoaded) return

    const apiKey = config.getUsersnapAPIKey({ isStudioCohort })
    /** UserSnap feedback pop up */
    addFeedBackScript(apiKey)
  }, [courses, isContextLoading, isStudioCohort, isUserSnapLoaded])

  return (
    <Footer data-testid='footer'>
      <ListGroupWrapper>
        <ListGroup>
          <ListItem>
            <Link href={TERMS_URL} target='_blank' rel='noopener noreferrer'>
              <span> Terms of Use </span>
            </Link>
          </ListItem>
          <ListItem>
            <Link href={PRIVACY_URL} target='_blank' rel='noopener noreferrer'>
              <span> Privacy Policy </span>
            </Link>
          </ListItem>
        </ListGroup>
      </ListGroupWrapper>
    </Footer>
  )
}

export default AppFooter
