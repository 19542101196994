import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({ url, rel = 'noopener noreferrer', children }) => {
  return (
    <a href={url} rel={rel}>
      {children}
    </a>
  )
}

ExternalLink.propTypes = {
  url: PropTypes.string,
  rel: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default ExternalLink
