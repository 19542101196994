import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import $script from 'scriptjs'
import { useUserStore } from '@outlier-org/lst-auth-react'

const CONVERTFLOW_SCRIPT = 'https://js.convertflow.co/production/websites/28553.js'

const ConvertFlowLoader = () => {
  const user = useUserStore(state => state.user)

  const location = useLocation()

  useEffect(() => {
    const loadConvertFlow = () => {
      $script(CONVERTFLOW_SCRIPT, 'convertflow', () => {
        if (window.convertflow && window.$cf) {
          initializeConvertFlow()
        }
      })
    }

    const initializeConvertFlow = () => {
      try {
        const { email } = user || {}

        // Ensure ConvertFlow is ready before interacting with it
        if (typeof window.convertflow?.identify === 'function') {
          if (email) {
            window.convertflow.identify({ email, override: true })
          }
          window.convertflow.start()
        } else {
          console.error('$cf is not defined or ConvertFlow is not fully initialized')
        }
      } catch (error) {
        console.error('Error initializing ConvertFlow:', error)
      }
    }

    // Remove any existing ConvertFlow script to reload on route changes
    const existingScript = document.getElementById('convertflow-script')
    if (existingScript) {
      existingScript.remove()
    }

    loadConvertFlow()
  }, [location.pathname, user])

  return null
}

export default ConvertFlowLoader
