import React from 'react'
import CardInfo from './CardInfo'
import config from '../../config'
import get from 'lodash/get'

import {
  getVisitedPracticeExerciseInfo
} from '../../utilities/section'

import {
  getCohortUpcomingExamDates
} from '../../utilities/cohort'
import { ExamLink } from './style'
import { secondsSinceEpoch } from '../../utilities/dateTime'
import CourseLaunchLink from '../CourseLaunchLink/CourseLaunchLink'

const PracticeExercisesCard = ({
  course,
  note,
  exam,
  handleStartButtonClick,
  courseProgress,
  chapterUuid,
  chapterNo,
  sectionNo,
  noAssessments,
  reviewChapterTitle,
  reviewChapterUrl,
  reviewFirstSectionUUID
}) => {
  const { id, chapters, cohort } = course

  const { studentProgress, sectionData } = courseProgress
  const {
    studentAnswers,
    'practice-exercises-complete': practiceExercisesComplete
  } = studentProgress

  const {
    section_uuid: sectionUuid,
    section_exe: sectionExe
  } = sectionData
  const { practice_exercises: practiceExercises } = sectionExe

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const isFirstSection = chapterNo === 1 && sectionNo === 1
  const visitedPracticeExercises =
    getVisitedPracticeExerciseInfo(studentAnswers, practiceExercises, isFirstSection)

  const {
    title,
    id: practiceExerciseId,
    remaining,
    visited,
    completed
  } = visitedPracticeExercises

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const problemSetUrl = `${sectionUrl}/${practiceExerciseId}`
  const {
    isStudyGuideOpen,
    openExamStudyGuide,
    title: examTitle,
    studyGuideNote,
    examOpen,
    examLoading
  } = exam

  const getDescription = (
    isSectionPracticeExercisesComplete,
    isPracticeExcerciseCompleted,
    sectionData = {}
  ) => {
    const { nextChapterNo, nextSectionNo, nextSectionUrl } = sectionData

    const isExamOpenAndSectionPracticeExercisesCompleted =
      isSectionPracticeExercisesComplete && examOpen
    const isStudyGuideOpenAndSectionPracticeExercisesCompleted =
      isSectionPracticeExercisesComplete && isStudyGuideOpen
    const isExamOpenAndPracticeExcerciseCompleted =
      isPracticeExcerciseCompleted && examOpen
    const isStudyGuideOpenAndPracticeExcerciseCompleted =
      isPracticeExcerciseCompleted && isStudyGuideOpen

    const isMultipleVisitedQuestions = visited > 1 ? 's' : ''

    if (isExamOpenAndSectionPracticeExercisesCompleted && reviewFirstSectionUUID) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            {title}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {'. Continue to '}
          <a href={nextSectionUrl} rel='noopener noreferrer'>
            Section {nextChapterNo}.{nextSectionNo}
          </a>
          {', start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {examTitle}
          </ExamLink>, {'or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (isExamOpenAndSectionPracticeExercisesCompleted) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            {title}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {'. Continue to '}
          <a href={nextSectionUrl} rel='noopener noreferrer'>
            Section {nextChapterNo}.{nextSectionNo}
          </a>
          , {!isStudyGuideOpen && 'or'} start
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {examTitle}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {' or view the '}
              <span onClick={() => openExamStudyGuide()}>Study Guide</span>
              {' to review the course material'}
            </>
          )}
            .
        </>
      )
    }

    if (isStudyGuideOpenAndSectionPracticeExercisesCompleted) {
      return (
        <>
          {`The ${examTitle} is coming up! You finished Problem Exercises in `}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
          Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {'. View the '}
          <span onClick={() => openExamStudyGuide()} rel='noopener noreferrer'>
          Study Guide
          </span>
          {' or continue to '}
          <a href={nextSectionUrl} rel='noopener noreferrer'>
          Section {nextChapterNo}.{nextSectionNo}
          </a>
          .
        </>
      )
    }

    if (isSectionPracticeExercisesComplete) {
      return (
        <>
          {'You finished '}
          <a href={problemSetUrl} rel='noopener noreferrer'>
            {title}
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          {'. Continue to '}
          <a href={nextSectionUrl} rel='noopener noreferrer'>
            Section {nextChapterNo}.{nextSectionNo}
          </a>
          .
        </>
      )
    }

    if (isExamOpenAndPracticeExcerciseCompleted && reviewFirstSectionUUID) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            {title}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          , start
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {examTitle}
          </ExamLink>, {'or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (isExamOpenAndPracticeExcerciseCompleted) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            {title}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          , start
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {examTitle}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {' or view the '}
              <span onClick={() => openExamStudyGuide()}>Study Guide</span>
              {' to review the course material'}
            </>
          )}
            .
        </>
      )
    }

    if (examOpen && reviewFirstSectionUUID) {
      return (
        <>
          {`It's exam time! Continue to complete the remaining ${remaining}`}
          {' questions in '}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            Problem Set
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          , start
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {examTitle}
          </ExamLink>, {'or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (examOpen) {
      return (
        <>
          {`It's exam time! Continue to complete the remaining ${remaining}`}
          {' questions in '}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            Problem Set
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          , start
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {examTitle}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {' or view the '}
              <span onClick={() => openExamStudyGuide()}>Study Guide</span>
              {' to review the course material'}
            </>
          )}
            .
        </>
      )
    }

    if (isStudyGuideOpenAndPracticeExcerciseCompleted) {
      return (
        <>
          {`The ${examTitle} is coming up! You completed`}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            {title}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
          Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          {'. View the '}
          <span onClick={() => openExamStudyGuide()} rel='noopener noreferrer'>
          Study Guide
          </span>
          {' or continue to keep working on the material.'}
        </>
      )
    }

    if (isStudyGuideOpen) {
      return (
        <>
          {`The ${examTitle} is coming up! View the `}
          <span onClick={() => openExamStudyGuide()} rel='noopener noreferrer'>
            Study Guide
          </span>
          {` or continue to complete the remaining ${remaining} questions in `}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            {title}
          </CourseLaunchLink>
          {' of '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          .
        </>
      )
    }

    if (isPracticeExcerciseCompleted) {
      return (
        <>
          {'You finished '}
          <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
            {title}
          </CourseLaunchLink>
          {' in '}
          <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </CourseLaunchLink>
          . Continue to keep working on the material
          {reviewFirstSectionUUID && (
            <>
              {' or start '}
              <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
              </a>
            </>
          )}
          .
        </>
      )
    }

    return (
      <>
        {`You completed ${visited} question${isMultipleVisitedQuestions} in `}
        <CourseLaunchLink url={problemSetUrl} rel='noopener noreferrer'>
          {title}
        </CourseLaunchLink>
        {' of '}
        <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
          Section {chapterNo}.{sectionNo}
        </CourseLaunchLink>
        . Continue to complete the remaining {remaining} questions
        {reviewFirstSectionUUID && (
          <>
            {' or start '}
            <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
            </a>
          </>
        )}
        .
      </>
    )
  }

  const handleNextSection = ({
    nextChapterNo,
    nextSectionNo,
    nextSectionUrl
  }) => {
    const navigateToNextSection = () =>
      (window.location.href = nextSectionUrl)

    return {
      dataTestId: 'all-practice-exercises-completed',
      description: getDescription(true, false, {
        nextChapterNo,
        nextSectionNo,
        nextSectionUrl
      }),
      note,
      studyGuideNote,
      secondaryBtnTxt: examOpen ? 'Start' : isStudyGuideOpen && 'Continue',
      handleSecondaryButton: () => {
        examOpen
          ? handleStartButtonClick()
          : isStudyGuideOpen && navigateToNextSection()
      },
      primaryBtnTxt: !isStudyGuideOpen || examOpen ? 'Continue' : 'View',
      handlePrimaryButton: () => {
        !isStudyGuideOpen || examOpen
          ? navigateToNextSection()
          : openExamStudyGuide()
      }
    }
  }

  // when last activity is practice exercises and all the practice exercises completed
  const handleCompletedPracticeExercisesSection = () => {
    const chapterIndex = chapters.findIndex(c => c.chapter_uuid === chapterUuid)
    const chapter = chapters[chapterIndex]

    if (sectionNo < chapter.sections.length) { // if chapter's next section exists
      const nextSection = chapter.sections[sectionNo]
      const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
      return handleNextSection({
        nextChapterNo: chapterNo,
        nextSectionNo: sectionNo + 1,
        nextSectionUrl
      })
    } else if (chapterIndex < chapters.length - 1) { // if chapter next section don't exist
      let nextChapter = chapters[chapterIndex + 1]
      const { type } = nextChapter
      if (type === 'chapter') { // check if we next chapter is chapter not exam
        const nextSection = nextChapter.sections[0]
        const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
        return handleNextSection({
          nextChapterNo: chapterNo + 1,
          nextSectionNo: 1,
          nextSectionUrl
        })
      } else { // check if next chapter is exam not chapter
        const currentTime = secondsSinceEpoch()
        const upcomingExamDates =
          getCohortUpcomingExamDates(cohort, currentTime, nextChapter)
        if (!upcomingExamDates && !nextChapter.isFinalExam) {
          nextChapter = chapters[chapterIndex + 2]
          const nextSection = nextChapter?.sections?.[0] || {}
          const nextSectionUrl = `${courseBaseUrl}/#/${get(nextSection, 'section_uuid', '')}`
          return handleNextSection({
            nextChapterNo: chapterNo + 1,
            nextSectionNo: 1,
            nextSectionUrl
          })
        }
      }
    }
    return {}
  }

  const navigateToSection = () =>
    (window.location.href = sectionUrl)

  const navigateToProblemSet = () =>
    (window.location.href = problemSetUrl)

  const navigateToReviewChapterUrl = () =>
    (window.location.href = reviewChapterUrl)

  let data = null
  if (completed) {
    data = {
      dataTestId: 'practice-exercises-completed',
      description: getDescription(false, true),
      note,
      studyGuideNote,
      secondaryBtnTxt:
        examOpen || reviewFirstSectionUUID
          ? 'Start'
          : isStudyGuideOpen && 'Continue',
      handleSecondaryButton: () => {
        if (!examOpen) {
          navigateToSection()
          return
        }
        reviewFirstSectionUUID ? navigateToReviewChapterUrl() : handleStartButtonClick()
      },
      primaryBtnTxt: isStudyGuideOpen && !examOpen ? 'View' : 'Continue',
      handlePrimaryButton: () => {
        isStudyGuideOpen && !examOpen ? openExamStudyGuide() : navigateToSection()
      }
    }
  } else {
    const viewStudyGuide = isStudyGuideOpen && !examOpen
    data = {
      dataTestId: 'practice-exercises-inprogress',
      description: getDescription(),
      note,
      studyGuideNote,
      secondaryBtnTxt: examOpen ? 'Start' : isStudyGuideOpen && 'Continue',
      handleSecondaryButton: () => {
        examOpen
          ? handleStartButtonClick()
          : navigateToProblemSet()
      },
      primaryBtnTxt: viewStudyGuide ? 'View' : 'Continue',
      handlePrimaryButton: () => {
        viewStudyGuide
          ? openExamStudyGuide()
          : navigateToProblemSet()
      }
    }
  }

  // Check practice exercises section is completed
  const isSectionPracticeExercisesComplete = practiceExercisesComplete[sectionUuid]

  // When all the practice exercises completed for a secion and assessments are locked
  if (isSectionPracticeExercisesComplete && noAssessments) {
    data = handleCompletedPracticeExercisesSection()
  }

  return (
    <CardInfo
      course={course}
      {...data}
    />
  )
}

export default PracticeExercisesCard
