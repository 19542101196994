import {
  FIRST_EDITION_SUBDOMAIN,
  REACT_APP_SHOPIFY_URL,
  COLLEGE_SUCCESS,
  REACT_APP_DOMAIN,
  NODE_ENV
} from './Constants'
import {
  OUTLIER_API_BASE_URL,
  OUTLIER_BASE_DOMAIN
} from './Constants/domains'
import util from './utilities'
import { trim } from 'lodash'

const qs = require('qs')

export default {
  tokensLaunchDate: '2022-04-11',
  termsUpdatedDate: '2021-04-28',
  CWIV2TermDate: '2024-01-14',
  isCollegeSuccessCourse,
  courseBaseUrl: getCourseBaseUrl(),
  courseBaseUrlById: getCourseBaseUrlById,
  apiHost: getApiHost(),
  courseIdToName: courseIdToName,
  isTitleUpdates: isTitleUpdates(),
  whatsNextUpdate: whatsNextUpdate(),
  courseImage: getCourseImage,
  courseIcon: getCourseIcon,
  getCourseIds,
  courseIds: getCourseIds(),
  getUsersnapAPIKey,
  getProctorioExamSettings,
  getKalturaSettings: getKalturaSettings(),
  configureKalturaPlayer,
  auth0BaseURL: `https://${REACT_APP_DOMAIN}`,
  nonPittsburghCohortNames: getNonPittsburghCohortNames(),
  flags: getFlags(),
  studentEmailOverride: getStudentEmailOverride(),
  projectedGradeReferenceDate: 1614931200, // Mar-05-2021 00:00:00 PST
  philosophyUpdatedGradingReleaseDate: 1641369600, // Jan-05-2022 00:00:00 PST
  proctorioExamUUIDReleaseDate: 1633503600, // Oct-06-2021 00:00:00 PDT,
  hideQuizForAuditorsCutOffDate: 1693724400, // Sep 3, 2023 00:00:00 PDT
  refundPolicyLink: `https://www.${OUTLIER_BASE_DOMAIN}/pages/refunds`,
  showTokenActivityLog,
  hasPracExerciseFlag: hasPracExerciseFlag(),
  shopifyUrl: REACT_APP_SHOPIFY_URL || 'https://outlier-us.myshopify.com/',
  isStudentProgress: isStudentProgress(getFlags()),
  hasNotificationFlag: hasNotificationFlag(getFlags()),
  hasAchievementsFlag: hasAchievementsFlag(getFlags()),
  getUserAssignedCourses,
  hasVoucherNumber: getHasVoucherNumber(),
  hasLesson0Flag,
  hasAnnouncementsFlag,
  isEmailVerified: () => window.location.search.includes('verifyEmail=true'),
  getAdditionalCourseIdsById,
  hasCourseActiveLearningTheme,
  additionalCourseIds: CourseIds(),
  hasUseAttempt: hasUseAttemptFlag(),
  getUPJCourseIdFromGGUCourseId,
  isCourseWithSingleMidtermExam,
  hasInTimeGradeFlag: hasInTimeGrade(),
  hasCmPartnerUpdateFlag: hasCmPartnerUpdate(),
  hasPartnerDashboardBannerFlag,
  hasDashboardRedesignFlag: hasDashboardRedesignFlag(),
  freeCourseIds: getFreeCourseIds(),
  isOutlierEngineer,
  hasRealizeClassSync: hasRealizeClassSyncFlag()
}

// 39 week extended courses
const extendedCourseNames = [
  'business.ext',
  'sociology.ext',
  'principlesofeconomics.ext',
  'computerscience-i.ext',
  'calculus.ext',
  'astronomy.ext',
  'microeconomics.ext',
  'macroeconomics.ext',
  'psychology.ext',
  'statistics.ext',
  'collegealgebra.ext',
  'precalculus.ext',
  'financialaccounting.ext',
  'professionalcommunication.ext'
]

function getFreeCourseIds () {
  return ['ckyp0xzra00003e5zdb9d8mta']
}

function getStudentEmailOverride () {
  return localStorage.studentEmailOverride
}

function getCourseBaseUrl () {
  if (NODE_ENV === 'development') {
    return 'http://localhost:3000'
  }
  return window.location.origin
}

function getCourseBaseUrlById (courseId) {
  const trimmedCourseId = trim(courseId)
  const courseName = courseIdToName(trimmedCourseId)
  const betaUrl = `https://${courseName}.${FIRST_EDITION_SUBDOMAIN}.${OUTLIER_BASE_DOMAIN}`
  const defaultUrl = `https://${courseName}.${OUTLIER_BASE_DOMAIN}`
  const defaultCourses = [
    'calculus',
    'psychology',
    'y.calculus',
    'astronomy',
    ...extendedCourseNames,
    'itsupport.cert',
    'dataanalytics-i.cert',
    'projectmanagement.cert',
    'digitalmarketing-i.cert',
    'digitalmarketing-ii.cert',
    'dataanalytics-ii.cert',
    'salesoperations.cert'
  ]

  const calculusRenderUrl = localStorage.getItem('calculusRenderUrl')

  if (calculusRenderUrl) {
    return `${calculusRenderUrl}?courseName=${courseName}`
  }

  return defaultCourses.includes(courseName) || courseName?.includes('.plus')
    ? defaultUrl : betaUrl
}

function getApiHost () {
  let apiHostFromLocalStorage = localStorage.apiHost
  const apiHostFromURL =
    window.location.search && qs.parse(window.location.search.slice(1)).apiHost

  if (!apiHostFromLocalStorage && !apiHostFromURL) {
    return OUTLIER_API_BASE_URL
  }

  if (apiHostFromURL) {
    localStorage.apiHost = apiHostFromURL
    apiHostFromLocalStorage = localStorage.apiHost
  }

  return apiHostFromLocalStorage
}

function courseIdToName (id) {
  return Object.keys(CourseIds()).find(
    courseName => CourseIds()[courseName].includes(id)
  )
}

function getCourseImage (courseId) {
  const courseName = courseIdToName(courseId)?.replace('.plus', '')
  return {
    calculus: 'images/calculus.jpg',
    psychology: 'images/psychology.jpg',
    statistics: 'images/statistics.jpg',
    astronomy: 'images/astronomy.jpg',
    precalculus: 'images/precalculus.jpg',
    collegealgebra: 'images/algebra.jpg',
    collegesuccess: 'images/default.jpg',
    professionalcommunication: 'images/default.jpg',
    principlesofeconomics: 'images/macroeconomics.jpg',
    business: 'images/default.jpg',
    'collegewriting-i': 'images/college-writing.jpg',
    'calculus-ii': 'images/calculus.jpg',
    'collegewriting-ii': 'images/college-writing.jpg',
    'physics-i': 'images/college-writing.jpg',
    macroeconomics: 'images/macroeconomics.jpg',
    financialaccounting: 'images/financial-accounting.jpg',
    sociology: 'images/sociology.jpeg',
    philosophy: 'images/philosophy.jpg',
    microeconomics: 'images/microeconomics.jpg',
    'y.calculus': 'images/calculus.jpg'
  }[courseName]
}

function getCourseIcon (courseId, whiteVariation) {
  const courseName = courseIdToName(courseId)?.replace('.plus', '')
  return {
    calculus: whiteVariation
      ? 'images/icons/calculus_I-white.svg'
      : 'images/icons/calculus_I.svg',

    'calculus-ii': whiteVariation
      ? 'images/icons/calculus_I-white.svg'
      : 'images/icons/calculus_I.svg',

    psychology: whiteVariation
      ? 'images/icons/psychology-white.svg'
      : 'images/icons/psychology.svg',

    statistics: whiteVariation
      ? 'images/icons/statistics-white.svg'
      : 'images/icons/statistics.svg',

    astronomy: whiteVariation
      ? 'images/icons/astronomy-white.svg'
      : 'images/icons/astronomy.svg',

    precalculus: whiteVariation
      ? 'images/icons/precalculus-white.svg'
      : 'images/icons/precalculus.svg',

    'computerscience-i': whiteVariation
      ? 'images/icons/precalculus-white.svg'
      : 'images/icons/precalculus.svg',

    'physics-i': whiteVariation
      ? 'images/icons/precalculus-white.svg'
      : 'images/icons/precalculus.svg',

    managerialaccounting: whiteVariation
      ? 'images/icons/precalculus-white.svg'
      : 'images/icons/precalculus.svg',

    collegealgebra: whiteVariation
      ? 'images/icons/precalculus-white.svg'
      : 'images/icons/precalculus.svg',

    collegesuccess: whiteVariation
      ? 'images/icons/precalculus-white.svg'
      : 'images/icons/precalculus.svg',

    professionalcommunication: whiteVariation
      ? 'images/icons/precalculus-white.svg'
      : 'images/icons/precalculus.svg',

    principlesofeconomics: whiteVariation
      ? 'images/icons/Macroeconomics-white.svg'
      : 'images/icons/Macroeconomics.svg',

    macroeconomics: whiteVariation
      ? 'images/icons/Macroeconomics-white.svg'
      : 'images/icons/Macroeconomics.svg',

    financialaccounting: whiteVariation
      ? 'images/icons/financial-accounting-white.svg'
      : 'images/icons/financial-accounting.svg',

    sociology: whiteVariation
      ? 'images/icons/sociology-white.svg'
      : 'images/icons/sociology-white.svg',

    business: whiteVariation
      ? 'images/icons/business-white.svg'
      : 'images/icons/business-white.svg',

    'collegewriting-i': whiteVariation
      ? 'images/icons/college-writing-white.svg'
      : 'images/icons/college-writing.svg',

    'collegewriting-ii': whiteVariation
      ? 'images/icons/college-writing-white.svg'
      : 'images/icons/college-writing.svg',

    philosophy: whiteVariation
      ? 'images/icons/philosophy-white.svg'
      : 'images/icons/philosophy.svg',

    microeconomics: whiteVariation
      ? 'images/icons/Microeconomics-white.svg'
      : 'images/icons/Microeconomics.svg',

    'y.calculus': whiteVariation
      ? 'images/icons/calculus-white.svg'
      : 'images/icons/calculus.svg'
  }[courseName] || 'images/icons/place-holder-course-icon-white.svg'
}

function getKalturaSettings () {
  // Kaltura account identification number
  const PARTNER_ID = 2654411
  // The player instance ID created in the KMC (Kaltura Management Console)
  // It holds the player configuration.
  const UICONF_ID = 45079531
  return {
    PARTNER_ID,
    UICONF_ID,
    scriptUrl: `https://cdnapisec.kaltura.com/p/${PARTNER_ID}/sp/${PARTNER_ID}00/embedIframeJs/uiconf_id/${UICONF_ID}/partner_id/${PARTNER_ID}`
  }
}

function configureKalturaPlayer (id = '', PARTNER_ID, UICONF_ID) {
  const KalturaVideoPlayer = document.querySelector('#kaltura_video_player')
  if (!KalturaVideoPlayer) return
  if (window?.KWidget?.thumbEmbed) {
    window.KWidget.thumbEmbed({
      targetId: 'kaltura_video_player',
      wid: `_${PARTNER_ID}`,
      uiconf_id: UICONF_ID,
      flashvars: {},
      cache_st: 1636010327,
      entry_id: id
    })
  }
}

function getCourseIds () {
  return {
    test: 'test-course',
    barnardhighlights: 'cktt8s66100003g5v8872yin9',
    content: 'cl2grnv7z00003f6302c1ccbf',
    token: 'ckv72bcw70000a2t95qftcx6r',
    calculus: 'b227c462-332c-40e0-8735-ea3af6f11661',
    psychology: '1e2f466d-049a-41e7-af53-74afbfa9d87b',
    statistics: 'ckdampe3b00003g5m6l85b1s5',
    astronomy: 'ckdf2158p00003g5milp6mvj8',
    collegealgebra: 'ckw6tiqy100003e5xexynd0dz',
    collegesuccess: 'ckyp0xzra00003e5zdb9d8mta',
    precalculus: 'ckp356yiy00003h5vpz3fbu1h',
    professionalcommunication: 'cl8929un900003b68j8k16w6a',
    principlesofeconomics: 'ckxyytpau00003f5xhe3sf4xj',
    'computerscience-i': 'cl0sd8la200003e62p8cxq2ey',
    managerialaccounting: 'cl0h7sg9f00003e620m2mlma8',
    macroeconomics: 'ckp3587wu00003h5v6jfbv37v',
    'collegewriting-i': 'cku4ayye700003g5vsb6jgmhb',
    'collegewriting-ii': 'cl31r155p00003f63wxa1sq4z',
    'physics-i': 'cl0h6maex00003e625n0dtois',
    'calculus-ii': 'cl9pxqbj300003b685ixsl873',
    business: 'cku4ayarl00003g5v8zya64ih',
    financialaccounting: 'ckp359ieh00003h5vm158kdt0',
    sociology: 'ckp3592gx00003h5vsd5ijmp9',
    philosophy: 'ckgqsu5lf00003h5lzot6as6x',
    microeconomics: 'ckiawlgvw00003h5o8c6u6rog',
    'astronomy.ext': 'clzbmp7cm00003b6evkcd0srr',
    'microeconomics.ext': 'clzbrh8hn00003b6f7yjprec7',
    'macroeconomics.ext': 'clzbr1j2400003b6er0vculdk',
    'y.calculus': 'ea88ffd3-5c59-49d5-89b4-b9f009dde9ac',
    'calculus.plus': 'cl8chwjku00003b68m0uulv59',
    'computerscience-i.plus': 'cla70y6pd00003b68xl5o929f',
    'collegealgebra.plus': 'cl8cc3qps00003b68vf2xvbx2',
    'collegewriting-i.plus': 'cl8bpkss900003b68lww2m8az',
    'collegewriting-ii.plus': 'clg6yvuyc00003b67eoyh7lz1',
    'astronomy.plus': 'cl9ipiyvr00003b68940h4yf7',
    'business.plus': 'cl8chrori00003b68iatqg9ds',
    'financialaccounting.plus': 'cl8cicil200003b686pscg7nt',
    'macroeconomics.plus': 'cl8cifhox00003b683k6qy7gk',
    'microeconomics.plus': 'cl8cieub000003b68hturuo2t',
    'philosophy.plus': 'cl8cig0e700003b68mgbqnry2',
    'psychology.plus': 'cl8chvsoy00003b68m0t56gfv',
    'sociology.plus': 'cl8cib1gy00003b682ct750ba',
    'statistics.plus': 'cl8ci9dna00003b684igglvbn',
    'precalculus.plus': 'cl8d5dbhz00003b689avjvmsv',
    'principlesofeconomics.plus': 'cl8cigudr00003b68gdw3vcxn',
    'professionalcommunication.plus': 'clg6ywvo000003b676omcaa9t',
    'business.ext': 'clix6ozhf00003b67f9cfxryn',
    'sociology.ext': 'clixipgk800003b6727vle7hi',
    'principlesofeconomics.ext': 'clixiovxz00003b671j9lggjw',
    'computerscience-i.ext': 'clixingmb00003b67f9nlibtx',
    'calculus.ext': 'clj65g8fi00003b670xricu75',
    'psychology.ext': 'clixio9dp00003b67fimpf4h2',
    'statistics.ext': 'clix6t3q600003b67l08i0ljb',
    'collegealgebra.ext': 'clix6s4i500003b67roqr1l1p',
    'precalculus.ext': 'clix6q93y00003b67mbuz6lm4',
    'financialaccounting.ext': 'clix6mm3e00003b67x74owz2b',
    'professionalcommunication.ext': 'clixiqdxw00003b67c8r80fb3',
    'itsupport.cert': 'cljst951400003b69tq4jnltv',
    'projectmanagement.cert': 'clieyyjpp00003b69h8dnhmtu',
    'dataanalytics-i.cert': 'clgb29uhc00003b67wtuupjqy',
    'digitalmarketing-i.cert': 'clgcltmgf00003b675y2hecxi',
    'dataanalytics-ii.cert': 'cloipjvx900003b6c9irc9j7d',
    'digitalmarketing-ii.cert': 'cloipl0py00003b6cxbtn9t2s',
    'salesoperations.cert': 'clgclvp9o00003b675vmu4250'
  }
}

function getUPJCourseIdFromGGUCourseId (courseId) {
  const courseName = courseIdToName(courseId)
  const upjCourseName = courseName?.replace('.plus', '')
  return getCourseIds()[upjCourseName]
}

function getAdditionalCourseIdsById (id) {
  const courseName = courseIdToName(id)
  return CourseIds()[courseName]
}

function CourseIds () {
  return {
    token: ['ckv72bcw70000a2t95qftcx6r'],
    calculus: ['b227c462-332c-40e0-8735-ea3af6f11661'],
    psychology: ['1e2f466d-049a-41e7-af53-74afbfa9d87b'],
    statistics: ['ckdampe3b00003g5m6l85b1s5'],
    astronomy: ['ckdf2158p00003g5milp6mvj8', 'ckzmyc33d00003e5z5ju9w077'],
    collegealgebra: ['ckw6tiqy100003e5xexynd0dz'],
    collegesuccess: ['ckyp0xzra00003e5zdb9d8mta'],
    precalculus: ['ckp356yiy00003h5vpz3fbu1h'],
    professionalcommunication: ['cl8929un900003b68j8k16w6a'],
    principlesofeconomics: ['ckxyytpau00003f5xhe3sf4xj'],
    'computerscience-i': ['cl0sd8la200003e62p8cxq2ey'],
    managerialaccounting: ['cl0h7sg9f00003e620m2mlma8'],
    macroeconomics: ['ckp3587wu00003h5v6jfbv37v'],
    'collegewriting-i': ['cku4ayye700003g5vsb6jgmhb'],
    'collegewriting-ii': ['cl31r155p00003f63wxa1sq4z'],
    'physics-i': ['cl0h6maex00003e625n0dtois'],
    'calculus-ii': ['cl9pxqbj300003b685ixsl873'],
    business: ['cku4ayarl00003g5v8zya64ih'],
    financialaccounting: ['ckp359ieh00003h5vm158kdt0'],
    sociology: ['ckp3592gx00003h5vsd5ijmp9'],
    philosophy: ['ckgqsu5lf00003h5lzot6as6x'],
    microeconomics: ['ckiawlgvw00003h5o8c6u6rog'],
    'y.calculus': ['ea88ffd3-5c59-49d5-89b4-b9f009dde9ac'],
    'calculus.plus': ['cl8chwjku00003b68m0uulv59'],
    'computerscience-i.plus': ['cla70y6pd00003b68xl5o929f'],
    'collegealgebra.plus': ['cl8cc3qps00003b68vf2xvbx2'],
    'collegewriting-i.plus': ['cl8bpkss900003b68lww2m8az', 'clpx286t000013b6buj1q8rgq'],
    'astronomy.plus': ['cl9ipiyvr00003b68940h4yf7'],
    'business.plus': ['cl8chrori00003b68iatqg9ds'],
    'financialaccounting.plus': ['cl8cicil200003b686pscg7nt'],
    'macroeconomics.plus': ['cl8cifhox00003b683k6qy7gk'],
    'microeconomics.plus': ['cl8cieub000003b68hturuo2t'],
    'philosophy.plus': ['cl8cig0e700003b68mgbqnry2'],
    'psychology.plus': ['cl8chvsoy00003b68m0t56gfv'],
    'sociology.plus': ['cl8cib1gy00003b682ct750ba'],
    'statistics.plus': ['cl8ci9dna00003b684igglvbn'],
    'precalculus.plus': ['cl8d5dbhz00003b689avjvmsv'],
    'principlesofeconomics.plus': ['cl8cigudr00003b68gdw3vcxn'],
    'professionalcommunication.plus': ['clg6ywvo000003b676omcaa9t'],
    'collegewriting-ii.plus': ['clg6yvuyc00003b67eoyh7lz1'],
    'business.ext': ['clix6ozhf00003b67f9cfxryn'],
    'sociology.ext': ['clixipgk800003b6727vle7hi'],
    'principlesofeconomics.ext': ['clixiovxz00003b671j9lggjw'],
    'computerscience-i.ext': ['clixingmb00003b67f9nlibtx'],
    'calculus.ext': ['clj65g8fi00003b670xricu75'],
    'psychology.ext': ['clixio9dp00003b67fimpf4h2'],
    'statistics.ext': ['clix6t3q600003b67l08i0ljb'],
    'collegealgebra.ext': ['clix6s4i500003b67roqr1l1p'],
    'precalculus.ext': ['clix6q93y00003b67mbuz6lm4'],
    'financialaccounting.ext': ['clix6mm3e00003b67x74owz2b'],
    'professionalcommunication.ext': ['clixiqdxw00003b67c8r80fb3'],
    'itsupport.cert': ['cljst951400003b69tq4jnltv'],
    'projectmanagement.cert': ['clieyyjpp00003b69h8dnhmtu'],
    'dataanalytics-i.cert': ['clgb29uhc00003b67wtuupjqy'],
    'digitalmarketing-i.cert': ['clgcltmgf00003b675y2hecxi'],
    'dataanalytics-ii.cert': ['cloipjvx900003b6c9irc9j7d'],
    'digitalmarketing-ii.cert': ['cloipl0py00003b6cxbtn9t2s'],
    'salesoperations.cert': ['clgclvp9o00003b675vmu4250'],
    'microeconomics.ext': ['clzbrh8hn00003b6f7yjprec7'],
    'macroeconomics.ext': ['clzbr1j2400003b6er0vculdk'],
    'astronomy.ext': ['clzbmp7cm00003b6evkcd0srr']
  }
}

function getNonPittsburghCohortNames () {
  return [
    'Calculus - Summer 2020 - FP',
    'Psychology - Summer 2020 - MakeSchool'
  ]
}

function showTokenActivityLog () {
  const flags = getFlags()
  return !!flags.tokenActivityLog
}

function isStudentProgress (flags) {
  const studentProgress = flags.studentProgress
  return !!studentProgress
}

function hasNotificationFlag (flags) {
  const notificationFlag = flags.notification
  return !!notificationFlag
}

function hasAchievementsFlag (flags) {
  return !!flags.achievements
}

function getUserAssignedCourses () {
  const flags = getFlags()
  return !!flags.fetchAssignedCourses
}

function hasLesson0Flag () {
  const flags = getFlags()
  return !!flags.lesson0
}

function hasPartnerDashboardBannerFlag () {
  const flags = getFlags()
  return !!flags.partnerDashboardBanner
}

function isTitleUpdates () {
  const flags = getFlags()
  return !!flags.titleUpdates
}

function whatsNextUpdate () {
  const flags = getFlags()
  return !!flags.whatsNextUpdate
}

function hasPracExerciseFlag () {
  const flags = getFlags()
  return !!flags.pracExercise
}

function getHasVoucherNumber () {
  const flags = getFlags()
  return !!flags.voucherNumber
}

function hasAnnouncementsFlag () {
  const flags = getFlags()
  return !!flags.announcements
}

function hasUseAttemptFlag () {
  const flags = getFlags()
  return !!flags.hasUseAttempt
}

function hasInTimeGrade () {
  const flags = getFlags()
  const inTimeGrade = flags.inTimeGrade
  return !!inTimeGrade
}

function hasCmPartnerUpdate () {
  const flags = getFlags()
  const cmPartnerUpdate = flags.cmPartnerUpdate
  return !!cmPartnerUpdate
}

function hasDashboardRedesignFlag () {
  const flags = getFlags()
  return !!flags.dashboardRedesign
}

function hasRealizeClassSyncFlag () {
  const flags = getFlags()
  return !!flags.hasRealizeClassSync
}

function getFlags () {
  const query = qs.parse(window.location.search.slice(1))
  Object.entries(query).forEach(([k, v]) => {
    delete query[k]
    if (k.indexOf('flag-') !== 0) return
    query[k.replace('flag-', '')] = v
  })
  return util.parseBooleanObject(query)
}

function getProctorioExamSettings () {
  const setting = 'recordvideo,recordaudio,recordscreen,recordwebtraffic,verifyvideo,verifyaudio,verifydesktop,verifyidauto,verifysignature,clipboard,closetabs,onescreen,print,downloads,rightclick,agentreentry'

  return setting
}

function isCollegeSuccessCourse (id) {
  const courseName = courseIdToName(id)
  return courseName === COLLEGE_SUCCESS
}

function getUsersnapAPIKey ({
  isStudioCohort = false
}) {
  return isStudioCohort
    ? '8a599f04-a4da-4e32-962e-4e63c38dca2f'
    : '39f91528-799b-4663-bb5f-4f17d4f510f6'
}

function hasCourseActiveLearningTheme (courseId) {
  if (!courseId) return false
  const courseIds = getCourseIds()

  return [
    courseIds.calculus,
    courseIds['y.calculus'],
    courseIds['calculus.plus'],
    courseIds['calculus.ext']
  ].includes(courseId)
}

function isCourseWithSingleMidtermExam (courseId) {
  const { philosophy, 'philosophy.plus': philosophyPlus } = getCourseIds()
  return [philosophy, philosophyPlus].includes(courseId)
}

function isOutlierEngineer (email) {
  if (!email) return false
  return email?.endsWith('@engineering.outlier.org')
}
