import React from 'react'
import CardInfo from './CardInfo'
import config from '../../config'
import {
  getCohortStartSecondsSinceEpoch
} from '../../utilities/course'
import { getExternalLinkUrl, redirectUserToExternalLink } from '@outlier-org/lst-auth-react'
import {
  getOrientationChapterSectionUrl,
  getFirstSectionUrl
} from '../../utilities/chapterUtils'
import { isGGUCourse } from '../../utilities/gguDegreeUtils'
import CourseLaunchLink from '../CourseLaunchLink/CourseLaunchLink'

export const UpcomingCard = ({ course }) => {
  const { cohort, id, name } = course
  const { courseInfoUrl, isPartnerCohort } = cohort

  const orientationSectionUrl = getOrientationChapterSectionUrl(course)
  const sectionUrl = orientationSectionUrl || getFirstSectionUrl(course)

  const internalResourcesPage = config.courseBaseUrlById(id) + '/#/resources'
  const resourcesUrl = isPartnerCohort ? courseInfoUrl : getExternalLinkUrl(internalResourcesPage)

  const startDateSinceEpoch = getCohortStartSecondsSinceEpoch(cohort)
  const startDate = new Date(startDateSinceEpoch)
  const month = startDate.toLocaleString('en-US', { month: 'short' })
  const day = startDate.getDate()

  const isCardDisabled = isGGUCourse(name)

  const getDescription = () => {
    if (isCardDisabled) {
      return (
        <>
          The course will open on {month} {day}. Check back then to get started on your coursework!
        </>
      )
    } else {
      return (
        <>
          The full course opens on {month} {day}, but you can access
          the first section now. Review the <a href={resourcesUrl} rel='noopener noreferrer'>Course Toolkit</a> or start
          with <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>{orientationSectionUrl ? 'Student Orientation' : 'Section 1.1'}</CourseLaunchLink> today.
        </>
      )
    }
  }

  return (
    <CardInfo
      course={course}
      description={getDescription()}
      dataTestId='upcoming-card-id'
      activityHasNotStarted
      disabled={isCardDisabled}
      {...(!isCardDisabled ? {
        primaryBtnTxt: 'Start',
        secondaryBtnTxt: 'Resources',
        handlePrimaryButton: () => { redirectUserToExternalLink(sectionUrl) },
        handleSecondaryButton: () => { window.location.href = resourcesUrl }
      } : {}
      )}

    />
  )
}

export default UpcomingCard
