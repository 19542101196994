import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from '../ExternalLink/ExternalLink'
import { getExternalLinkUrl } from '@outlier-org/lst-auth-react'

/**
 * Component to render an external link for launching a course.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.url=''] - The URL to launch the course.
 * @param {React.ReactNode} props.children - The content to be displayed inside the link.
 * @returns {JSX.Element} The rendered external link component.
 */
const CourseLaunchLink = ({ url = '', children }) => {
  const courseLaunchUrl = getExternalLinkUrl(url)

  return (
    <ExternalLink url={courseLaunchUrl || '#'}>
      {children}
    </ExternalLink>
  )
}

CourseLaunchLink.propTypes = {
  courseLaunchUrl: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default CourseLaunchLink
