import styled from 'styled-components'
import boxArrowRight from '../../assets/icons/box-arrow-up-right.svg'

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #25272B;
  padding: 24px;
  margin-top: 64px !important;
  margin-bottom: -64px !important;
`

export const Icon = styled.img.attrs({
  src: boxArrowRight
})`
  alt: 'box arrow right';
  width: 16px;
  height: 16px;
  padding: 2px;
  opacity: 0px;
  vertical-align: middle;
`

export const Message = styled.p`
  font-size: 16px;
  line-height: normal;
  color: white;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  margin: 0px auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%; // Ensure the Message takes up the full width

  span {
    color: #5FC4B8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
  }
`
