import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import AppGuard from './Components/AppGuard/AppGuard'
import { AuthProvider, getRumbaSsoServiceUrl } from '@outlier-org/lst-auth-react'
import ContextProvider from './Components/ContextProvider/ContextProvider'
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { publicRoutes } from './utilities/authUtils'
import api from './api'
import {
  REACT_APP_ID,
  REACT_APP_SSO_AUTH_SAPI_BASE_URL,
  REACT_APP_SSO_AUTH_CONTEXT_ID,
  REACT_APP_SSO_AUTH_GATEWAY_CLIENT_ID,
  REACT_APP_SSO_BASE_URL,
  REACT_APP_SSO_RBS_TOKEN_GRANT_TYPE
} from '../src/Constants/index'

/**
 * Configuration object for authentication.
 *
 * @typedef {Object} AuthConfig
 * @property {string} appId - The application ID, defaults to 'student-dashboard'.
 * @property {string} authGatewayBaseUrl - The base URL for the authentication gateway.
 * @property {string} authContextId - Unique ID from the dashboard.
 * @property {string} authGatewayClientId - Client ID for the authentication gateway, to be confirmed with devops.
 * @property {boolean} useUserRoleAsRbsScope - Flag to use user role as RBS scope.
 * @property {string} ssoBaseUrl - The base URL for single sign-on.
 * @property {string} rbsTokenGrantType - The grant type for RBS token.
 * @property {string} authCallbackRoute - The route for authentication callback, defaults to '/'.
 * @property {boolean} includeLicensedProducts - Flag to include licensed products, defaults to false.
 * @property {boolean} includePolicy - Flag to include policy, defaults to true.
 * @property {boolean} redirectToCustomError - Flag to redirect to custom error page, defaults to false.
 * @property {string} serviceUrl - The service URL, defaults to the current window location origin.
 * @property {Array<string>} publicRoutes - List of public routes.
 * @property {Function} onPostAuth - Callback function to handle post-authentication.
 */
export const lstAuthConfig = {
  appId: REACT_APP_ID || 'student-dashboard',
  authGatewayBaseUrl: REACT_APP_SSO_AUTH_SAPI_BASE_URL,
  authContextId: REACT_APP_SSO_AUTH_CONTEXT_ID, // Unique Id - from dashboard
  authGatewayClientId: REACT_APP_SSO_AUTH_GATEWAY_CLIENT_ID, // Need to check with devops
  useUserRoleAsRbsScope: true,
  ssoBaseUrl: REACT_APP_SSO_BASE_URL,
  rbsTokenGrantType: REACT_APP_SSO_RBS_TOKEN_GRANT_TYPE,
  authCallbackRoute: '/',
  includeLicensedProducts: false,
  includePolicy: true,
  redirectToCustomError: true,
  serviceUrl: getRumbaSsoServiceUrl(),
  publicRoutes,
  onPostAuth: api.handlePostAuthCallback
}

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <AuthProvider initialConfig={lstAuthConfig}>
        <AppGuard>
          <ContextProvider>
            <App />
          </ContextProvider>
        </AppGuard>
      </AuthProvider>
    </Router>
  </ErrorBoundary>
  , document.getElementById('root'))

serviceWorkerRegistration.register()
