import React from 'react'
import {
  Container,
  IntroHeader,
  Title,
  TitleIcon
} from '../ChecklistIntro/styled'

const NoCourse = () => {
  return (
    <Container marginTop='23.5px' data-testid='noCourse'>
      <IntroHeader>
        <TitleIcon
          src='images/icons/icon-checklist-feature-updated.svg'
          data-testid='noCourseIcon'
        />
        <Title>You don’t have any courses.</Title>
      </IntroHeader>
    </Container>
  )
}

export default NoCourse
