import React, { useContext, useState } from 'react'
import CardInfo from './CardInfo'
import config from '../../config'

import {
  chaptersVisitedSections,
  getExamFromExamNumber
} from '../../utilities/chapterUtils'

import { redirectUserToExternalLink } from '@outlier-org/lst-auth-react'
import {
  secondsToFormattedDateTimeShort,
  secondsToDateString,
  getTimezoneShort
} from '../../utilities/dateTime'
import { getLatestCohort } from '../../utilities/course'
import {
  getProctorioExamTag,
  getProctorioCourseHomeUrl,
  getProctorioExamTake,
  allowLiveProctoring
} from '../../utilities/proctorio'
import {
  getCohortStartSecondsSinceEpoch
} from '../../utilities/cohort'
import api from '../../api'
import { getToken } from '../../utilities/axios'
import { ExamLink } from './style'
import ActivityInProgressAndCompletedCard from './ActivityInProgressAndCompletedCard'
import { AppContext } from '../ContextProvider/ContextProvider'
import CourseLaunchLink from '../CourseLaunchLink/CourseLaunchLink'

const ExamOpenAndCompletedCard = ({
  course,
  examOpenData,
  openExamStudyGuide,
  isCaughtUp,
  courseProgress,
  noAssessments
}) => {
  const { courses: studentCourses } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  if (noAssessments) return null

  const { id, chapters } = course

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const { studentProgress, sectionData } = courseProgress

  const { examOpen, lockDate, isFinalExam } = examOpenData
  const exam = getExamFromExamNumber({
    chapters,
    examNumber: examOpen,
    isFinalExam
  })
  if (!exam) return null

  const {
    chapter_uuid: chapterUuid,
    exams: { Question: questions }
  } = exam

  if (!questions || !questions.length) return null

  // handle exam completed card
  const handleExamCompleted = () => {
    const { Question_uuid: questionUuid } = questions[0]

    const examUrl = `${courseBaseUrl}/#/${chapterUuid}/${questionUuid}`
    const examPercentage = studentProgress['exam-percentage']
    const examRetakePercentage = getRetakePercentage(exam, examPercentage)
    const percentage = examRetakePercentage ?? examPercentage[chapterUuid]

    let chapterNo = 0
    for (let i = 0; i < chapters.length; i++) {
      if (chapters[i].type === 'chapter') chapterNo += 1
      if (chapters[i].chapter_uuid !== chapterUuid) continue

      let sectionUrl = ''
      if (chapters.length !== i + 1) {
        const unlockedChapter = chapters[i + 1]
        const { sections } = unlockedChapter
        sectionUrl = `${courseBaseUrl}/#/${sections[0].section_uuid}`
      }
      let description = ''
      if (isFinalExam) {
        // completed card description for final exam
        description = (
          <>
            {'You completed the '}
            <CourseLaunchLink url={examUrl} rel='noopener noreferrer'>{exam.title}</CourseLaunchLink>
            {` and scored ${percentage}%. Review your answers or visit the `}
            <CourseLaunchLink url={courseBaseUrl} rel='noopener noreferrer'>Course Homepage</CourseLaunchLink>
            {' to keep working.'}
          </>
        )
      } else {
        // completed card description for midterm exams
        description = (
          <>
            {'You completed '}
            <CourseLaunchLink url={examUrl} rel='noopener noreferrer'>{exam.title}</CourseLaunchLink>
            {` and scored ${percentage}%. Review your answers or continue to `}
            <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>Section {chapterNo + 1}.1</CourseLaunchLink>.
          </>
        )
      }
      return {
        dataTestId: 'exam-completed',
        description,
        primaryBtnTxt: 'Continue',
        secondaryBtnTxt: 'Review',
        handlePrimaryButton: () => {
          redirectUserToExternalLink(
            !isFinalExam
              ? sectionUrl
              : courseBaseUrl
          )
        },
        handleSecondaryButton: () => { redirectUserToExternalLink(examUrl) }
      }
    }
    return null
  }

  // handle exam open card
  const handleExamOpen = ({ isLoading, course }) => {
    const chapterVisitedSections = chaptersVisitedSections(chapters, studentProgress)
    const cohortName = course?.cohort?.name?.toLowerCase() || ''
    const isVipCohort = cohortName.includes('vip')

    for (let i = chapterVisitedSections.length - 1; i >= 0; i--) {
      const { visitedSections, chapterNo } = chapterVisitedSections[i]
      for (let j = visitedSections.length - 1; j >= 0; j--) {
        const {
          sectionNo,
          sectionUuid
        } = visitedSections[j]

        if (sectionData.section_uuid !== sectionUuid) continue

        const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`

        // convert lock date to short timezone i.e 31 July, 1:00 PST
        const lockDateInSeconds = lockDate / 1000
        const formattedDate = secondsToFormattedDateTimeShort(
          lockDateInSeconds)
          .replace(', ', ' at ')
        const timezone = getTimezoneShort(secondsToDateString(lockDateInSeconds))
        const { examStudyGuide, title, chapter_uuid: examId } = exam
        const { file: { url } = {} } = examStudyGuide || {}
        const isStudyGuideOpen = url
        const link = url || sectionUrl

        const note = isVipCohort ? <></> : <>The exam is open until {formattedDate} {timezone}</>

        const renderProgressedActivity = () => {
          return (
            <ActivityInProgressAndCompletedCard
              course={course}
              handleStartButtonClick={() => handleStartButtonClick()}
              note={note}
              examOpenData={examOpenData}
              exam={{
                ...exam,
                examOpen,
                examLoading: isLoading,
                isStudyGuideOpen,
                openExamStudyGuide: () => openExamStudyGuide(link, examId)
              }}
              courseProgress={courseProgress}
              noAssessments={noAssessments}
            />
          )
        }

        const comp = !isCaughtUp && renderProgressedActivity()
        if (comp) {
          return comp
        }

        return {
          dataTestId: 'exam-open',
          description: (
            <>
              It’s exam time! Start the
              <ExamLink
                disable={isLoading}
                onClick={() => handleStartButtonClick()}
              >
                {title}
              </ExamLink>
              {' or '}
              {!isStudyGuideOpen ? (
                <>
                  {'continue to '}
                  <a href={link}>
                    Section {chapterNo}.{sectionNo}
                  </a>
                  {' to keep working and review course material.'}
                </>
              ) : (
                <>
                  {'view the '}
                  <span onClick={() => openExamStudyGuide(link, examId)}>
                    Study Guide
                  </span>
                  {' to review the course material.'}
                </>
              )}
            </>
          ),
          note: isVipCohort ? <></> : <>The exam closes {formattedDate} {timezone} </>,
          primaryBtnTxt: 'Start',
          handlePrimaryButton: () => handleStartButtonClick(),
          secondaryBtnTxt: !url ? 'Continue' : 'View',
          handleSecondaryButton: () => {
            url
              ? openExamStudyGuide(url, examId)
              : redirectUserToExternalLink(sectionUrl)
          }
        }
      }
    }
    return null
  }

  const handleStartButtonClick = async () => {
    const currentCourse = studentCourses.find((course) => course.id === id)
    const isLiveProctoring = allowLiveProctoring(currentCourse)
    if (isLiveProctoring) {
      const { Question_uuid: questionUuid } = questions[0]
      redirectUserToExternalLink(`${courseBaseUrl}/#/${chapterUuid}/${questionUuid}`)
      return
    }

    const proctorioUrls = await getProctorioUrls()
    if (!proctorioUrls) return

    const { launchUrl } = proctorioUrls

    const token = await getToken()

    const url = getProctorioCourseHomeUrl(courseBaseUrl, token, launchUrl)
    redirectUserToExternalLink(url)
  }

  const getProctorioUrls = async () => {
    const cohort = getLatestCohort(course)
    const { name: cohortName } = cohort
    const startDate = getCohortStartSecondsSinceEpoch(cohort)

    const examTake = getProctorioExamTake(
      courseBaseUrl,
      exam.chapter_uuid,
      exam.exams.Question[0].Question_uuid
    )

    const examTag = getProctorioExamTag(cohortName, exam, startDate)

    setIsLoading(true)
    const proctorioUrls = await api.getProctorioUrls(examTake, examTag, course.id)
    setIsLoading(false)
    return proctorioUrls
  }

  const getRetakePercentage = (exam, examPercentage) => {
    const retakeUuid = exam?.examVersions?.[0]?.uuid
    return examPercentage[retakeUuid]
  }

  // Check if exam completed or not
  const examComplete = studentProgress['exam-complete']
  const isExamCompleted = chapterUuid in examComplete

  /* If exam is completed it renders exam completed card
    else it renders exam open card */
  const data = isExamCompleted
    ? handleExamCompleted({
      course,
      exam,
      examOpen
    })
    : handleExamOpen({
      course,
      exam,
      isLoading,
      lockDate
    })

  if (!data) return null

  if (React.isValidElement(data)) {
    return React.cloneElement(data)
  }

  return (
    <CardInfo
      course={course}
      {...data}
      isLoading={isLoading}
    />
  )
}

export default ExamOpenAndCompletedCard
