import React from 'react'
import CardInfo from './CardInfo'
import api from '../../api'
import { COLLEGE_SUCCESS_STARTED_EVENT } from '../../Constants/eventType'
import { ONCE } from '../../Constants/frequency'

import { redirectUserToExternalLink } from '@outlier-org/lst-auth-react'
import {
  getFirstSectionUrl
} from '../../utilities/chapterUtils'
import config from '../../config'
import { getCollegeSuccessEventData } from '../../utilities/course'
import CourseLaunchLink from '../CourseLaunchLink/CourseLaunchLink'

const ActivityNotYetStartedCard = ({
  course
}) => {
  const { isCollegeSuccessCourse } = config
  const sectionUrl = getFirstSectionUrl(course)
  const isCollegeSuccess = isCollegeSuccessCourse(course.id)
  const data = {
    dataTestId: 'activities-not-yet-started',
    description: (
      <>
        The course is open! Jump into the coursework as soon as you're ready.
        Start with <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>Section 1.1</CourseLaunchLink>.
      </>
    ),
    primaryBtnTxt: 'Start',
    handlePrimaryButton: async () => {
      if (isCollegeSuccess) {
        const eventProperties = {
          properties:
            getCollegeSuccessEventData(course),
          event: COLLEGE_SUCCESS_STARTED_EVENT,
          frequency: ONCE
        }
        await api.submitTrackedEvent(eventProperties, course?.id, course?.cohort?.id)
      }
      redirectUserToExternalLink(sectionUrl)
    }
  }

  return (
    <CardInfo
      course={course}
      {...data}
      activityHasNotStarted
    />
  )
}

export default ActivityNotYetStartedCard
