import React from 'react'
import config from '../../config'
import CardInfo from './CardInfo'
import CourseLaunchLink from '../CourseLaunchLink/CourseLaunchLink'

const CompletedCard = ({
  course,
  handleCourses
}) => {
  const { id, displayName } = course
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const handleCrossIcon = () => {
    handleCourses(id)
  }

  return (
    <CardInfo
      handleCrossIcon={handleCrossIcon}
      course={course}
      description={
        <>
          The course has ended. Going forward,
          {' you can still access certain course content by selecting '}
          <CourseLaunchLink url={courseBaseUrl} rel='noopener noreferrer'>{displayName}</CourseLaunchLink>
          {' in '}
          <a href='/#my-courses' rel='noopener noreferrer'>My Courses</a>.
        </>
      }
    />
  )
}

export default CompletedCard
