import React from 'react'
import CardInfo from '../CardInfo'
import { toWords } from 'number-to-words'
import capitalize from 'lodash/capitalize'
import CourseLaunchLink from '../../CourseLaunchLink/CourseLaunchLink'
import { redirectUserToExternalLink } from '@outlier-org/lst-auth-react'

const ClosedCard = ({
  course,
  note,
  almostClosing,
  assignmentsCount,
  assignment,
  courseBaseUrl,
  sectionUrl,
  sectionName
}) => {
  const singleAssignment = assignmentsCount === 1
  const assignmentUrl = `${courseBaseUrl}/#/${assignment.chapter_uuid}/writing_assignment`

  const description = almostClosing
    ? singleAssignment ? (
      <>
        <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
          {assignment.title}
        </CourseLaunchLink> is closing soon! View the assignment or continue to
        <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </CourseLaunchLink>
      to keep working.
      </>
    ) : (
      <>
        {capitalize(toWords(assignmentsCount))} assignments are closing soon!
      Visit the <CourseLaunchLink url={courseBaseUrl} rel='noopener noreferrer'> Course Homepage </CourseLaunchLink>
      to view the assignments or continue to
        <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </CourseLaunchLink>
      to keep working.
      </>
    )
    : singleAssignment ? (
      <>
        <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
          {assignment.title}
        </CourseLaunchLink> has just closed. Continue to
        <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </CourseLaunchLink>
      to keep working.
      </>
    ) : (
      <>
        {capitalize(toWords(assignmentsCount))} assignments have just closed.
      Continue to
        <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </CourseLaunchLink>
      to keep working.
      </>
    )

  return (
    <CardInfo
      course={course}
      description={description}
      note={note}
      primaryBtnTxt={almostClosing ? singleAssignment ? 'View' : 'View All' : 'Continue'}
      secondaryBtnTxt={almostClosing ? 'Continue' : ''}
      dataTestId='writing-closed'
      handlePrimaryButton={() => {
        redirectUserToExternalLink(
          almostClosing
            ? singleAssignment ? assignmentUrl : courseBaseUrl
            : sectionUrl
        )
      }}
      handleSecondaryButton={() => {
        redirectUserToExternalLink(almostClosing ? sectionUrl : '')
      }}
    />
  )
}

export default ClosedCard
