import React from 'react'
import CardInfo from '../CardInfo'
import { getExamFromExamNumber } from '../../../utilities/chapterUtils'
import CourseLaunchLink from '../../CourseLaunchLink/CourseLaunchLink'
import { redirectUserToExternalLink } from '@outlier-org/lst-auth-react'

const SubmittedCard = ({
  course,
  note,
  assignments,
  examOpenData,
  exams,
  courseBaseUrl,
  sectionUrl,
  sectionName,
  studentProgress
}) => {
  let submittedAssignment
  assignments.forEach(assignment => {
    submittedAssignment = assignment.isSubmitted ? assignment : submittedAssignment
  })

  const { chapters } = course
  const lastChapter = chapters[chapters.length - 1]
  const isLastChapter = lastChapter.chapter_uuid === submittedAssignment.chapter_uuid

  const isOpenSubmitted = submittedAssignment.isOpen
  const assignmentUrl = `${courseBaseUrl}/#/${submittedAssignment.chapter_uuid}/writing_assignment`
  sectionName = `Section ${sectionName}`

  const handleNextChapterExam = nextChapter => {
    const examProgress = studentProgress['exam-complete']
    const openExam = examOpenData && getExamFromExamNumber({
      chapters: exams,
      examNumber: examOpenData.examOpen,
      isFinalExam: examOpenData.isFinalExam
    })
    const nextChapterIsOpenExam = openExam &&
      nextChapter.chapter_uuid === openExam.chapter_uuid
    const nextChapterIsCompletedExam = examProgress &&
      nextChapter.chapter_uuid in examProgress
    if (!nextChapterIsOpenExam && !nextChapterIsCompletedExam) return
    const { chapter_uuid: chapterUUID, exams: { Question: questions } } = nextChapter
    sectionUrl = `${courseBaseUrl}/#/${chapterUUID}/${questions[0].Question_uuid}`
    sectionName = nextChapter.title
  }

  const handleNextChapterWriting = nextChapter => {
    const nextAssignment = assignments.find(assignment =>
      assignment.chapter_uuid === nextChapter.chapter_uuid)
    if (!nextAssignment.isOpen && !nextAssignment.isSubmitted) return
    sectionUrl = `${courseBaseUrl}/#/${nextChapter.chapter_uuid}/writing_assignment`
    sectionName = nextAssignment.title
  }

  if (!isLastChapter) {
    const assignmentChapterIndex = chapters.findIndex(chapter =>
      chapter.chapter_uuid === submittedAssignment.chapter_uuid)
    const nextChapter = chapters[assignmentChapterIndex + 1]
    nextChapter.type === 'exam' && handleNextChapterExam(nextChapter)
    nextChapter.type === 'WritingAssignmentChapterRecord' &&
      handleNextChapterWriting(nextChapter)
  }

  const openAndLastDescription = (
    <>
    You submitted
      <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
        {` ${submittedAssignment.title} `}
      </CourseLaunchLink>.
      Review your assignment or visit the
      <CourseLaunchLink url={courseBaseUrl} rel='noopener noreferrer'> Course Homepage </CourseLaunchLink>
    to keep working.
    </>
  )

  const openButNotLastDescription = (
    <>
    You submitted
      <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
        {` ${submittedAssignment.title} `}
      </CourseLaunchLink>.
      Review your assignment or continue to
      <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
        {` ${sectionName} `}
      </CourseLaunchLink>.
    </>
  )

  const closedAndLastDescription = (
    <>
    You submitted
      <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
        {` ${submittedAssignment.title} `}
      </CourseLaunchLink>.
    Visit the
      <CourseLaunchLink url={courseBaseUrl} rel='noopener noreferrer'> Course Homepage </CourseLaunchLink>
    to keep working.
    </>
  )

  const closedButNotLastDescription = (
    <>
    You submitted
      <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
        {` ${submittedAssignment.title} `}
      </CourseLaunchLink>.
    Continue to
      <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'>
        {` ${sectionName} `}
      </CourseLaunchLink> to keep working.
    </>
  )

  const description = isOpenSubmitted
    ? isLastChapter ? openAndLastDescription : openButNotLastDescription
    : isLastChapter ? closedAndLastDescription : closedButNotLastDescription

  return (
    <CardInfo
      course={course}
      description={description}
      note={note}
      primaryBtnTxt='Continue'
      secondaryBtnTxt={isOpenSubmitted ? 'Review' : ''}
      dataTestId='writing-submitted'
      handleSecondaryButton={() => { redirectUserToExternalLink(assignmentUrl) }}
      handlePrimaryButton={() => {
        redirectUserToExternalLink(isLastChapter ? courseBaseUrl
          : sectionUrl
        )
      }}
    />
  )
}

export default SubmittedCard
