import React from 'react'
import CardInfo from '../CardInfo'
import { toWords } from 'number-to-words'
import capitalize from 'lodash/capitalize'
import CourseLaunchLink from '../../CourseLaunchLink/CourseLaunchLink'
import { redirectUserToExternalLink } from '@outlier-org/lst-auth-react'

const OpenCard = ({
  course,
  note,
  openAssignmentsCount,
  openAssignment,
  courseBaseUrl,
  sectionUrl,
  sectionName
}) => {
  const singleAssignmentOpen = openAssignmentsCount === 1
  const assignmentUrl = `${courseBaseUrl}/#/${openAssignment.chapter_uuid}/writing_assignment`

  const description = singleAssignmentOpen ? (
    <>
      <CourseLaunchLink url={assignmentUrl} rel='noopener noreferrer'>
        {openAssignment.title}
      </CourseLaunchLink> is open! View the assignment or continue to
      <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </CourseLaunchLink>
      to keep working.
    </>
  ) : (
    <>
      {capitalize(toWords(openAssignmentsCount))} new assignments are open!
      Visit the <CourseLaunchLink url={courseBaseUrl} rel='noopener noreferrer'> Course Homepage </CourseLaunchLink>
      to view the assignments or continue to
      <CourseLaunchLink url={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </CourseLaunchLink>to keep working.
    </>
  )

  return (
    <CardInfo
      course={course}
      description={description}
      note={note}
      primaryBtnTxt={singleAssignmentOpen ? 'Start' : 'View All'}
      secondaryBtnTxt='Continue'
      dataTestId='writing-open'
      handlePrimaryButton={() => {
        redirectUserToExternalLink(singleAssignmentOpen
          ? assignmentUrl : courseBaseUrl
        )
      }}
      handleSecondaryButton={() => { redirectUserToExternalLink(sectionUrl) }}
    />
  )
}

export default OpenCard
