import React from 'react'
import { useAuthStore } from '@outlier-org/lst-auth-react'
import { isPublicRoute } from '../../utilities/authUtils'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'

const AppGuard = ({ children }) => {
  const [
    isAuthenticated,
    login,
    isLoading,
    isAuthenticationFailed
  ] = useAuthStore((state) => [
    state.isAuthenticated,
    state.login,
    state.isLoading,
    state.isAuthenticationFailed
  ])

  if (isPublicRoute() || isAuthenticated) {
    return children
  }

  if (!isLoading && isAuthenticationFailed) {
    return <ErrorBoundary />
  }

  if (!isLoading) {
    login()
  }

  return <LoadingSpinner />
}

export default AppGuard
